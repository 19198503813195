import { defineStore } from 'pinia'

export const usePlaybookActivitiesStore = defineStore('playbook-activities', () => {
  const projectId = ref('')

  const params = computed(() => ({
    path: {
      projectId: projectId.value,
    },
  }))

  const { data: activities, refresh, loading } = useQuery('/activities/project/{projectId}/v2', {
    params,
    options: {
      lazy: true,
    },
  })

  const fetchActivities = async (id: string) => {
    projectId.value = id
    await refresh()
  }

  return {
    loading,
    activities,
    fetchActivities,
  }
})
