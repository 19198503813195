<script setup lang="ts">
import type { IconClass, Status } from '#core/types'
import { useSlots } from 'vue'

type Variant = 'block' | 'inline'
type AlertStatus = Status | 'tip'

interface ColorClasses {
  container: string
  icon: string
}

interface VariantClasses {
  container: string
  content: string
  title: string
  text: string
  close: string
}

export interface UiAlertProps {
  variant?: Variant
  type?: AlertStatus
  title?: string
  icon?: IconClass
  text?: string
  cta?: string
  ctaVariant?: 'solid' | 'outline' | 'ghost' | 'link'
  ctaClass?: string
  action?: string
  inline?: boolean
  // trying to get this using $attrs but no joy
  onClose?: () => void
  onAction?: () => void
}

/**
 * UiAlert
 *
 * In-page alert (success, info, warning, error) component
 *
 * @see https://github.com/forged-com/forgd/pull/1675
 */
const props = withDefaults(
  defineProps<UiAlertProps>(),
  {
    variant: 'block',
    type: 'info',
  },
)

defineEmits(['close', 'action'])

const slots = useSlots()
const hasTitle = computed(() => slots.title || !!props.title)
const hasText = computed(() => slots.default || !!props.text)
const hasCta = computed(() => slots.cta || !!props.cta)

const icons: Record<AlertStatus, IconClass> = {
  success: 'i-heroicons-check-circle',
  warning: 'i-heroicons-exclamation-triangle',
  info: 'i-heroicons-information-circle',
  error: 'i-heroicons-exclamation-circle',
  tip: 'i-heroicons-light-bulb',
}

const iconClasses: Record<AlertStatus, ColorClasses> = {
  info: {
    container: 'bg-neutral-600/30',
    icon: 'text-forgd-primary-300',
  },
  success: {
    container: 'bg-forgd-green-600/10',
    icon: 'text-forgd-green-600',
  },
  warning: {
    container: 'bg-forgd-yellow-700/10',
    icon: 'text-forgd-yellow-700',
  },
  error: {
    container: 'bg-forgd-red-600/10',
    icon: 'text-forgd-red-600',
  },
  tip: {
    container: 'bg-neutral-600/30',
    icon: 'text-forgd-primary-300',
  },
}

const variantClasses: Record<Variant, VariantClasses> = {
  block: {
    container: 'p-4 gap-3.5',
    content: 'space-y-2',
    title: hasText.value
      ? 'font-semibold'
      : 'font-normal',
    text: 'space-y-2',
    close: 'right-3 top-3',
  },
  inline: {
    container: 'p-2 gap-1.5',
    content: 'space-y-1',
    title: 'font-normal',
    text: hasTitle.value
      ? 'space-y-1 text-xs'
      : 'space-y-1 text-xs mt-[2px]',
    close: 'right-1 top-1',
  },
}

const variant = computed<Variant>(() => props.inline ? 'inline' : props.variant)
const isBlock = computed(() => variant.value === 'block')
const isInline = computed(() => variant.value === 'inline')
const classes = computed(() => variantClasses[variant.value])
</script>

<template>
  <div
    data-ui="UiAlert"
    class="relative flex items-start w-full rounded-lg"
    :class="[
      iconClasses[type].container,
      classes.container,
    ]"
  >
    <!-- icon -->
    <UiIcon
      :name="icon || icons[type]"
      :class="iconClasses[type].icon"
      class="shrink-0"
      size="lg"
    />

    <!-- content -->
    <div
      class="text-sm font-display text-forgd-gray-600 grow font-normal"
      :class="classes.content"
    >
      <!-- title -->
      <div v-if="hasTitle" :class="classes.title">
        <slot name="title">
          {{ title || 'Missing title' }}

          <!-- inline action -->
          <slot v-if="hasCta && isInline && (action || onAction)" name="cta">
            <NuxtLink
              :to="action"
              class="hover:underline text-forgd-primary-300 cursor-pointer"
              :target="action?.startsWith('https') ? '_blank' : '_self'"
              @click="(event: MouseEvent) => onAction && event.preventDefault() || $emit('action')"
            >
              {{ cta }}
            </NuxtLink>
          </slot>
        </slot>
      </div>

      <!-- text -->
      <div v-if="hasText || (isBlock && cta)" :class="classes.text">
        <slot v-if="hasText">
          <p>{{ text }}</p>
        </slot>

        <!-- block action -->
        <slot v-if="hasCta && isBlock" name="cta">
          <UiButton
            :label="cta"
            :to="action"
            :variant="ctaVariant"
            :class="ctaClass"
            class="!mt-4"
            @click="() => onAction && $emit('action')"
          />
        </slot>
      </div>
    </div>

    <!-- close button -->
    <UiButton
      v-if="onClose"
      class="absolute"
      :class="classes.close"
      variant="ghost"
      size="xs"
      icon="i-heroicons-x-mark"
      @click="$emit('close')"
    />
  </div>
</template>

<style lang="scss">
[data-ui="UiAlert"] a[href] {
  @apply text-forgd-primary-300 font-semibold hover:underline;
}
</style>
