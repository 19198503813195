import { z } from 'zod'
import { initContract } from '@ts-rest/core'
import { ProjectPathParam } from '../types/common'
import {
  GetLiquidityBreakdownsQuery,
  GetLiquidityBreakdownsResult,
  GetVolumeBreakdownsQuery,
  GetVolumeBreakdownsResult,
  GetPerformanceInsightsQuery,
  GetPerformanceInsightsResult,
  GetMMComparisonQuery,
  GetMMComparisonResult,
  GetKPIReportQuery,
  GetKPIReportResult,
  GetVolumeBreakdownsByExchangeQuery,
  GetVolumeBreakdownsByExchangeResult,
  GetLiquidityBreakdownsByExchangeQuery,
  GetLiquidityBreakdownsByExchangeResult,
  GetMarketplaceLiquidityQuery,
  GetMarketplaceLiquidityResult,
  GetKPIDataQuery,
  GetKPIDataResult,
  GetProjectExchangesResult,
  GetEngagementsResult,
  GetEngagementDetailsResult,
} from '../types'
import { stringToIntNumber } from '@forgd/shared/utils/zod'

const c = initContract()

const forMarketMakerBasePath = 'liquidity-monitoring/for-mmaker'

export const liquidityMonitoringforMarketMakerContract = c.router({
  getLiquidityBreakdowns: {
    method: 'GET',
    path: `/${forMarketMakerBasePath}/liquidity-breakdowns/:projectId`,
    query: GetLiquidityBreakdownsQuery.omit({ marketMakerId: true }),
    pathParams: ProjectPathParam,
    responses: {
      200: GetLiquidityBreakdownsResult,
    },
    summary: 'Get liquidity breakdowns by project id',
  },
  getLiquidityBreakdownsByExchange: {
    method: 'GET',
    path: `/${forMarketMakerBasePath}/liquidity-breakdowns-by-exchange/:projectId`,
    query: GetLiquidityBreakdownsByExchangeQuery.omit({ marketMakerId: true }),
    pathParams: ProjectPathParam,
    responses: {
      200: GetLiquidityBreakdownsByExchangeResult,
    },
    summary: 'Get liquidity breakdowns by exchange for project id',
  },
  getVolumeBreakdowns: {
    method: 'GET',
    path: `/${forMarketMakerBasePath}/volume-breakdowns/:projectId`,
    query: GetVolumeBreakdownsQuery.omit({ marketMakerId: true }),
    pathParams: ProjectPathParam,
    responses: {
      200: GetVolumeBreakdownsResult,
    },
    summary: 'Get volume breakdowns by project id',
  },
  getVolumeBreakdownsByExchange: {
    method: 'GET',
    path: `/${forMarketMakerBasePath}/volume-breakdowns-by-exchange/:projectId`,
    query: GetVolumeBreakdownsByExchangeQuery.omit({ marketMakerId: true }),
    pathParams: ProjectPathParam,
    responses: {
      200: GetVolumeBreakdownsByExchangeResult,
    },
    summary: 'Get volume breakdowns grouped by exchange for project id',
  },
  getPerformanceInsights: {
    method: 'GET',
    path: `/${forMarketMakerBasePath}/performance-insights/:projectId`,
    query: GetPerformanceInsightsQuery.omit({ marketMakerId: true }),
    pathParams: ProjectPathParam,
    responses: {
      200: GetPerformanceInsightsResult,
    },
    summary: 'Get performance insights by project id',
  },
  getMMComparison: {
    method: 'GET',
    path: `/${forMarketMakerBasePath}/mm-comparison/:projectId`,
    query: GetMMComparisonQuery.omit({ marketMakerId: true }),
    pathParams: ProjectPathParam,
    responses: {
      200: GetMMComparisonResult,
    },
    summary: 'Get makert maker comparison data by project id',
  },
  getKPIReport: {
    method: 'GET',
    path: `/${forMarketMakerBasePath}/kpi-report/:projectId`,
    query: GetKPIReportQuery.omit({ marketMakerId: true }),
    pathParams: ProjectPathParam,
    responses: {
      200: GetKPIReportResult,
    },
    summary: 'Get market maker kpi reports data by project id',
  },
  getMarketplaceLiquidity: {
    method: 'GET',
    path: `/${forMarketMakerBasePath}/marketplace-liquidity/:projectId`,
    query: GetMarketplaceLiquidityQuery,
    pathParams: ProjectPathParam,
    responses: {
      200: GetMarketplaceLiquidityResult,
    },
    summary: 'Get market maker marketplace liquidity by project id',
  },
  getKPIData: {
    method: 'GET',
    path: `/${forMarketMakerBasePath}/kpi-data/:projectId`,
    query: GetKPIDataQuery,
    pathParams: ProjectPathParam,
    responses: {
      200: GetKPIDataResult,
    },
    summary: 'Get KPI data by project id',
  },
  getProjectExchanges: {
    method: 'GET',
    path: `/${forMarketMakerBasePath}/project-exchanges/:projectId`,
    pathParams: ProjectPathParam,
    responses: {
      200: GetProjectExchangesResult,
    },
    summary: 'Get project exchanges',
  },
  getAvailableDays: {
    method: 'GET',
    path: `/${forMarketMakerBasePath}/available-days/:projectId`,
    pathParams: ProjectPathParam,
    responses: {
      200: z.array(z.string().date()),
    },
    summary: 'Get available days for project',
  },
  getEngagementDetails: {
    method: 'GET',
    path: `/${forMarketMakerBasePath}/mm-engagement-details/:projectId`,
    responses: {
      200: GetEngagementDetailsResult,
      204: z.undefined(),
    },
    pathParams: ProjectPathParam,
    query: z.object({
      date: z.string().date(),
      daysLookback: z
        .string()
        .optional()
        .nullable()
        .transform(stringToIntNumber)
        .refine((val) => val == null || val > 0, {
          message: 'daysLookback must be a positive integer',
        }),
    }),
    summary: 'Get active engaged project details',
  },
  getEngagements: {
    method: 'GET',
    path: `/${forMarketMakerBasePath}/mm-engagements/:projectId`,
    pathParams: ProjectPathParam,
    responses: {
      200: GetEngagementsResult,
    },
    summary: 'Get active engaged market makers for project',
  },
})

export type LiquidityMonitoringForMarketMakerContractType = typeof liquidityMonitoringforMarketMakerContract
