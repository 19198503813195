import { z } from 'zod'

export const GetMarketMakerProjectResult = z.object({
  id: z.string().uuid(),
  name: z.string(),
  acceptedContactMarketMakers: z.boolean().nullable(),
  acceptedTerms: z.boolean().nullable(),
  isDashboardEnabled: z.boolean().nullish(),
  otherMarketMakers: z.array(z.string()).nullable(),
  projectCalendly: z.string().nullable(),
  projectEmail: z.string().nullable(),
  projectTelegram: z.string().nullable(),
  projectUrl: z.string().nullable(),
})
export type GetMarketMakerProjectResultType = z.infer<typeof GetMarketMakerProjectResult>

export const PostMarketMakerProjectSchema = z
  .object({
    acceptedContactMarketMakers: z.boolean().refine((val) => val === true),
    acceptedTerms: z.boolean().refine((val) => val === true),
    otherMarketMakers: z.array(z.string()).nullable(),
    projectCalendly: z.string().url().nullable(),
    projectEmail: z.string().email().nullable(),
    projectTelegram: z.string().nullable(),
    projectUrl: z.string().url().nullable(),
  })
  .superRefine((data, ctx) => {
    if (!data.projectEmail && !data.projectTelegram) {
      const message = 'Either email or telegram must be provided.'
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['projectEmail'],
        message,
      })
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['projectTelegram'],
        message,
      })
    }
  })
export type PostMarketMakerProjectSchemaType = z.infer<typeof PostMarketMakerProjectSchema>
