<script setup lang="ts" generic="T">
import { toControlOptions } from '#core/utils/form'
import { HEIGHTS } from './internal/common'

/**
 * UiSwitch
 *
 * Flexible switch component supporting binary or multiple options
 * with additional functionality for disabled, icons, colors
 *
 * - pass no options for a No/Yes toggle with boolean output
 * - pass array of ControlOptions, with value, label, icon, disabled, etc
 *
 * Utilizes toControlOptions(); you can also pass:
 *
 * - array of objects, i.e. [{ value, label, ... }, ... ]
 * - object of label:value pairs, i.e. { 'Label': value, ... }
 * - array of string labels, i.e. ['One', 'Two', ... ]
 * - string of pipe-separated labels, i.e. 'One|Two|...'
 *
 * Core, @see https://github.com/forged-com/forgd/pull/2380
 * Slots, @see https://github.com/forged-com/forgd/pull/2386
 */
interface Props {
  modelValue?: T
  options?: RawControlOptions
  size?: 'sm' | 'md' | 'lg'
  colors?: Presets | Colors[]
}

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
})

const emits = defineEmits([
  'change',
  'update:modelValue',
])

const attrs = useAttrs()

/// models
const options = computed<{ value: T, label: string }[]>(() => {
  return !props.options
    ? toControlOptions({ No: false, Yes: true })
    : toControlOptions(props.options)
})

// track current value indecently of props; this allows model-less slots to work
const currentValue = ref<T>(props.modelValue ?? options.value[0].value)
watch(() => props.modelValue, (value) => {
  currentValue.value = value
})

const selectedIndex = computed({
  get() {
    return options.value.findIndex(option => option.value === currentValue.value) || 0
  },
  set(index) {
    const option = options.value[index]
    currentValue.value = option.value
    emits('update:modelValue', option.value)
    emits('change', option.value, option)
  },
})

// emulate click functionality for toggles
function onClick(event: PointerEvent) {
  if (isToggle.value) {
    event.preventDefault()
    selectedIndex.value = selectedIndex.value === 0
      ? 1
      : 0
  }
}

/// flags
const isToggle = computed(() => {
  return options.value.length === 2 && options.value.every(item => typeof item.value === 'boolean')
})

/// style
const colors = {
  black: { bg: 'bg-forgd-accent-900', text: 'text-white' },
  red: { bg: 'bg-[#F3E5E5]', text: 'text-[#E02424]' },
  yellow: { bg: 'bg-[#EAE8D1]', text: 'text-[#7D730F]' },
  green: { bg: 'bg-[#C6DAD3]', text: 'text-[#03543F]' },
}

const presets = {
  'default': [colors.black, colors.black],
  'red-to-green': [colors.red, colors.yellow, colors.green],
  'green-to-red': [colors.green, colors.yellow, colors.red],
  'red-green': [colors.red, colors.green],
  'green-red': [colors.green, colors.red],
}

type Presets = keyof typeof presets
type Colors = keyof typeof colors

const preset = computed(() => {
  return Array.isArray(props.colors) ? props.colors?.map(key => colors[key]) : presets[(props.colors || 'default')]
})

function getColors(index: number): typeof colors[Colors] {
  return preset.value[index] || colors.black
}

const ui = computed(() => {
  // sizes
  const listHeight = HEIGHTS[props.size]
  const tabHeight = 'h-full'

  // color
  const index = selectedIndex.value

  const tabColor = getColors(index)

  const markerBackground = isToggle.value && index === 1
    ? 'bg-forgd-green-500'
    : tabColor.bg

  // return
  return defineUi('tabs', {
    wrapper: 'shrink-0 space-y-0',
    container: '',
    list: {
      base: attrs.class,
      width: '',
      background: 'bg-forgd-neutral-600/10 ring-forgd-neutral-600 ring-1',
      height: listHeight,
      padding: 'p-[4px]',
      marker: {
        background: markerBackground,
      },
      tab: {
        height: tabHeight,
        active: tabColor.text,
        inactive: 'disabled:text-gray-400 hover:text-forgd-bgd-900',
        size: 'text-xs',
        font: 'font-semibold',
        padding: 'px-2.5',
      },
    },
  })
})
</script>

<template>
  <UTabs
    v-model="selectedIndex"
    data-ui="UiSwitch"
    :items="options"
    :ui="ui"
    @click.capture="onClick"
  >
    <template v-if="!isToggle && $slots.default" #item="{ index, item }">
      <slot
        name="default"
        :index="index"
        :value="item.value"
        :option="item"
      />
    </template>
  </UTabs>
</template>

<style lang="scss">
div[data-ui="UiSwitch"] {
  & > div > div {
    transition-property: left;
  }
}
</style>
