import { default as _91_46_46_46slug_93kgKwLE3c9MMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/academy/pages/academy/[...slug].vue?macro=true";
import { default as indexn8DQShM4e9Meta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/confirm-email/index.vue?macro=true";
import { default as indexD4z3BkwaoUMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/email-redirect/index.vue?macro=true";
import { default as indexgqYez6MYylMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/index.vue?macro=true";
import { default as market_45maker_45detailsMeIeTvE90CMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/market-maker-details.vue?macro=true";
import { default as quote_45detailsacEeJ4vPwNMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/quote-details.vue?macro=true";
import { default as received_45quotesWkoYTcKuqnMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/received-quotes.vue?macro=true";
import { default as received_45requotes4jFJsWJYDsMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/received-requotes.vue?macro=true";
import { default as request_45for_45quotationsviudEzBh3vMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/request-for-quotations.vue?macro=true";
import { default as exchange_45ratingsHE42HnxFAMMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/exchange-ratings.vue?macro=true";
import { default as indexZOzdDdUHVRMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/index.vue?macro=true";
import { default as listing_45feesfLkc4Zrfa1Meta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/listing-fees.vue?macro=true";
import { default as listing_45questionnaireskvkS0uL9jHMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/listing-questionnaires.vue?macro=true";
import { default as indexgsp2ClLYNJMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/forgot-password/index.vue?macro=true";
import { default as indextEttFS3EZfMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/full-service-market-maker/index.vue?macro=true";
import { default as gitbookiKtaf3Qbp3Meta } from "/opt/render/project/src/apps/ui/pages/gitbook.vue?macro=true";
import { default as indexcZmILnmCwdMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/login/index.vue?macro=true";
import { default as indexY3SHM1WiHoMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/magic-link/index.vue?macro=true";
import { default as _91mode_93UUIykMBYoIMeta } from "/opt/render/project/src/apps/ui/layers/market-maker-monitoring/pages/market-maker-monitoring/[mode].vue?macro=true";
import { default as dashboardWEI51SKBbwMeta } from "/opt/render/project/src/apps/ui/layers/market-maker-monitoring/pages/market-maker-monitoring/dashboard.vue?macro=true";
import { default as index0kt5wjF8MYMeta } from "/opt/render/project/src/apps/ui/layers/market-maker-monitoring/pages/market-maker-monitoring/index.vue?macro=true";
import { default as mobile_45gatePs3ZIurrZqMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/pages/mobile-gate.vue?macro=true";
import { default as teammateFwZRghKNvFMeta } from "/opt/render/project/src/apps/ui/layers/onboarding/pages/onboarding/[organizationId]/teammate.vue?macro=true";
import { default as indexJXPgbQ9ZevMeta } from "/opt/render/project/src/apps/ui/layers/onboarding/pages/onboarding/index.vue?macro=true";
import { default as index50X0NDA6REMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/page-not-found/index.vue?macro=true";
import { default as _91activity_93FCYwNgMA7DMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/partner-activity-lead/[activity].vue?macro=true";
import { default as _91partner_9357ZGAlGOMbMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/partner-schedule/[partner].vue?macro=true";
import { default as indexxKloAqOzUDMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/password/new/index.vue?macro=true";
import { default as playbook_45basicaY2QO8YeerMeta } from "/opt/render/project/src/apps/ui/layers/dashboard/pages/playbook-basic.vue?macro=true";
import { default as playbook1oioyPigGFMeta } from "/opt/render/project/src/apps/ui/layers/dashboard/pages/playbook.vue?macro=true";
import { default as apiScCVUaZBxtMeta } from "/opt/render/project/src/apps/ui/pages/playground/api.vue?macro=true";
import { default as indexkiJWczTCQTMeta } from "/opt/render/project/src/apps/ui/layers/onboarding/pages/register/index.vue?macro=true";
import { default as indexl0UR4nUuzcMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/reset-password/index.vue?macro=true";
import { default as settingsHPro30BIZ4Meta } from "/opt/render/project/src/apps/ui/layers/account-settings/pages/settings.vue?macro=true";
import { default as publicywPirCbU8PMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/share/[ticker]/[projectId]/public.vue?macro=true";
import { default as supportgOUch7ei4AMeta } from "/opt/render/project/src/apps/ui/pages/support.vue?macro=true";
import { default as modeling_45sell_45pressureA2nTjLygCJMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/modeling-sell-pressure.vue?macro=true";
import { default as modeling_45supply_45and_45demandcOAboSHD3vMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/modeling-supply-and-demand.vue?macro=true";
import { default as optimizing_45circulating_45supplyN4hanynrdCMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/optimizing-circulating-supply.vue?macro=true";
import { default as simulating_45post_45tge_45pops5vEvEegP2cMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/simulating-post-tge-pops.vue?macro=true";
import { default as simulating_45price_45discoveryurGkp43p6BMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/simulating-price-discovery.vue?macro=true";
import { default as indexaBtn33Gle9Meta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/index.vue?macro=true";
import { default as demand_45driversASkVcYZWV0Meta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/demand-drivers.vue?macro=true";
import { default as estimating_45demand5QNWfSA49mMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/estimating-demand.vue?macro=true";
import { default as token_45distribution_45scheduleDKwalkEa1YMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/token-distribution-schedule.vue?macro=true";
import { default as token_45emission_45scheduleC4tFTetmaQMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/token-emission-schedule.vue?macro=true";
import { default as basic_45informationlUMahR1eKlMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/basic-information.vue?macro=true";
import { default as business_45modelxHW5vPs6b1Meta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/business-model.vue?macro=true";
import { default as mission_45visionhyP8tB43zYMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/mission-vision.vue?macro=true";
import { default as problem_45and_45solutionzMmJ7jxMVjMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/problem-and-solution.vue?macro=true";
import { default as product_45market_45fitKmPmji1IspMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/product-market-fit.vue?macro=true";
import { default as value_45captureafsCE8IPf1Meta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/value-capture.vue?macro=true";
import { default as value_45creationnqKWZH8uKsMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/value-creation.vue?macro=true";
import { default as fdv_45maximum_45token_45supplyhP8ge5J5rgMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/valuation-documenting/fdv-maximum-token-supply.vue?macro=true";
import { default as sanitizing_45tokenomicsD4w2tsPzS2Meta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/valuation-documenting/sanitizing-tokenomics.vue?macro=true";
import { default as token_45settingsPDUhAPfihbMeta } from "/opt/render/project/src/apps/ui/layers/dashboard/pages/token-settings.vue?macro=true";
import { default as indexABpPLza0lUMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-unlocks/index.vue?macro=true";
import { default as indexaB6DKjLij0Meta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/tokenomics/index.vue?macro=true";
import { default as authorizationrgPABm5vD5Meta } from "/opt/render/project/src/apps/ui/layers/onboarding/pages/v2/authorization.vue?macro=true";
import { default as _91code_930KenkwevttMeta } from "/opt/render/project/src/apps/ui/layers/onboarding/pages/v2/invitation/[code].vue?macro=true";
import { default as indexP3TFTIKcavMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/verify-email/index.vue?macro=true";
import { default as component_45stubumNe5hscdmMeta } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.38.4_@opentel_sysu2verttu62bk247oexq2hca/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubumNe5hscdm } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.38.4_@opentel_sysu2verttu62bk247oexq2hca/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "academy-slug",
    path: "/academy/:slug(.*)*",
    meta: _91_46_46_46slug_93kgKwLE3c9MMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/academy/pages/academy/[...slug].vue")
  },
  {
    name: "confirm-email",
    path: "/confirm-email",
    meta: indexn8DQShM4e9Meta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/confirm-email/index.vue")
  },
  {
    name: "email-redirect",
    path: "/email-redirect",
    meta: indexD4z3BkwaoUMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/email-redirect/index.vue")
  },
  {
    name: "engage-a-market-maker",
    path: "/engage-a-market-maker",
    meta: indexgqYez6MYylMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/index.vue")
  },
  {
    name: "engage-a-market-maker-market-maker-details",
    path: "/engage-a-market-maker/market-maker-details",
    meta: market_45maker_45detailsMeIeTvE90CMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/market-maker-details.vue")
  },
  {
    name: "engage-a-market-maker-quote-details",
    path: "/engage-a-market-maker/quote-details",
    meta: quote_45detailsacEeJ4vPwNMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/quote-details.vue")
  },
  {
    name: "engage-a-market-maker-received-quotes",
    path: "/engage-a-market-maker/received-quotes",
    meta: received_45quotesWkoYTcKuqnMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/received-quotes.vue")
  },
  {
    name: "engage-a-market-maker-received-requotes",
    path: "/engage-a-market-maker/received-requotes",
    meta: received_45requotes4jFJsWJYDsMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/received-requotes.vue")
  },
  {
    name: "engage-a-market-maker-request-for-quotations",
    path: "/engage-a-market-maker/request-for-quotations",
    meta: request_45for_45quotationsviudEzBh3vMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/request-for-quotations.vue")
  },
  {
    name: "exchange-listing-exchange-ratings",
    path: "/exchange-listing/exchange-ratings",
    meta: exchange_45ratingsHE42HnxFAMMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/exchange-ratings.vue")
  },
  {
    name: "exchange-listing",
    path: "/exchange-listing",
    meta: indexZOzdDdUHVRMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/index.vue")
  },
  {
    name: "exchange-listing-listing-fees",
    path: "/exchange-listing/listing-fees",
    meta: listing_45feesfLkc4Zrfa1Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/listing-fees.vue")
  },
  {
    name: "exchange-listing-listing-questionnaires",
    path: "/exchange-listing/listing-questionnaires",
    meta: listing_45questionnaireskvkS0uL9jHMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/listing-questionnaires.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: indexgsp2ClLYNJMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/forgot-password/index.vue")
  },
  {
    name: "full-service-market-maker",
    path: "/full-service-market-maker",
    meta: indextEttFS3EZfMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/full-service-market-maker/index.vue")
  },
  {
    name: "gitbook",
    path: "/gitbook",
    component: () => import("/opt/render/project/src/apps/ui/pages/gitbook.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexcZmILnmCwdMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/login/index.vue")
  },
  {
    name: "magic-link",
    path: "/magic-link",
    meta: indexY3SHM1WiHoMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/magic-link/index.vue")
  },
  {
    name: "market-maker-monitoring-mode",
    path: "/market-maker-monitoring/:mode()",
    meta: _91mode_93UUIykMBYoIMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/market-maker-monitoring/pages/market-maker-monitoring/[mode].vue")
  },
  {
    name: "market-maker-monitoring-dashboard",
    path: "/market-maker-monitoring/dashboard",
    meta: dashboardWEI51SKBbwMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/market-maker-monitoring/pages/market-maker-monitoring/dashboard.vue")
  },
  {
    name: "market-maker-monitoring",
    path: "/market-maker-monitoring",
    meta: index0kt5wjF8MYMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/market-maker-monitoring/pages/market-maker-monitoring/index.vue")
  },
  {
    name: "mobile-gate",
    path: "/mobile-gate",
    meta: mobile_45gatePs3ZIurrZqMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/pages/mobile-gate.vue")
  },
  {
    name: "onboarding-organizationId-teammate",
    path: "/onboarding/:organizationId()/teammate",
    meta: teammateFwZRghKNvFMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/onboarding/pages/onboarding/[organizationId]/teammate.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexJXPgbQ9ZevMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/onboarding/pages/onboarding/index.vue")
  },
  {
    name: "page-not-found",
    path: "/page-not-found",
    meta: index50X0NDA6REMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/page-not-found/index.vue")
  },
  {
    name: "partner-activity-lead-activity",
    path: "/partner-activity-lead/:activity()",
    meta: _91activity_93FCYwNgMA7DMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/partner-activity-lead/[activity].vue")
  },
  {
    name: "partner-schedule-partner",
    path: "/partner-schedule/:partner()",
    meta: _91partner_9357ZGAlGOMbMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/partner-schedule/[partner].vue")
  },
  {
    name: "password-new",
    path: "/password/new",
    meta: indexxKloAqOzUDMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/password/new/index.vue")
  },
  {
    name: "playbook-basic",
    path: "/playbook-basic",
    meta: playbook_45basicaY2QO8YeerMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/dashboard/pages/playbook-basic.vue")
  },
  {
    name: "playbook",
    path: "/playbook",
    meta: playbook1oioyPigGFMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/dashboard/pages/playbook.vue")
  },
  {
    name: "playground-api",
    path: "/playground/api",
    meta: apiScCVUaZBxtMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/pages/playground/api.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: indexkiJWczTCQTMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/onboarding/pages/register/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexl0UR4nUuzcMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/reset-password/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsHPro30BIZ4Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/account-settings/pages/settings.vue")
  },
  {
    name: "share-ticker-projectId-public",
    path: "/share/:ticker()/:projectId()/public",
    meta: publicywPirCbU8PMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/share/[ticker]/[projectId]/public.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: supportgOUch7ei4AMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/pages/support.vue")
  },
  {
    name: "token-designer-adjusting-modeling-sell-pressure",
    path: "/token-designer/adjusting/modeling-sell-pressure",
    meta: modeling_45sell_45pressureA2nTjLygCJMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/modeling-sell-pressure.vue")
  },
  {
    name: "token-designer-adjusting-modeling-supply-and-demand",
    path: "/token-designer/adjusting/modeling-supply-and-demand",
    meta: modeling_45supply_45and_45demandcOAboSHD3vMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/modeling-supply-and-demand.vue")
  },
  {
    name: "token-designer-adjusting-optimizing-circulating-supply",
    path: "/token-designer/adjusting/optimizing-circulating-supply",
    meta: optimizing_45circulating_45supplyN4hanynrdCMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/optimizing-circulating-supply.vue")
  },
  {
    name: "token-designer-adjusting-simulating-post-tge-pops",
    path: "/token-designer/adjusting/simulating-post-tge-pops",
    meta: simulating_45post_45tge_45pops5vEvEegP2cMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/simulating-post-tge-pops.vue")
  },
  {
    name: "token-designer-adjusting-simulating-price-discovery",
    path: "/token-designer/adjusting/simulating-price-discovery",
    meta: simulating_45price_45discoveryurGkp43p6BMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/simulating-price-discovery.vue")
  },
  {
    name: "token-designer",
    path: "/token-designer",
    meta: indexaBtn33Gle9Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/index.vue")
  },
  {
    name: "token-designer-modeling-demand-drivers",
    path: "/token-designer/modeling/demand-drivers",
    meta: demand_45driversASkVcYZWV0Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/demand-drivers.vue")
  },
  {
    name: "token-designer-modeling-estimating-demand",
    path: "/token-designer/modeling/estimating-demand",
    meta: estimating_45demand5QNWfSA49mMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/estimating-demand.vue")
  },
  {
    name: "token-designer-modeling-token-distribution-schedule",
    path: "/token-designer/modeling/token-distribution-schedule",
    meta: token_45distribution_45scheduleDKwalkEa1YMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/token-distribution-schedule.vue")
  },
  {
    name: "token-designer-modeling-token-emission-schedule",
    path: "/token-designer/modeling/token-emission-schedule",
    meta: token_45emission_45scheduleC4tFTetmaQMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/token-emission-schedule.vue")
  },
  {
    name: "token-designer-token-profile-basic-information",
    path: "/token-designer/token-profile/basic-information",
    meta: basic_45informationlUMahR1eKlMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/basic-information.vue")
  },
  {
    name: "token-designer-token-profile-business-model",
    path: "/token-designer/token-profile/business-model",
    meta: business_45modelxHW5vPs6b1Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/business-model.vue")
  },
  {
    name: "token-designer-token-profile-mission-vision",
    path: "/token-designer/token-profile/mission-vision",
    meta: mission_45visionhyP8tB43zYMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/mission-vision.vue")
  },
  {
    name: "token-designer-token-profile-problem-and-solution",
    path: "/token-designer/token-profile/problem-and-solution",
    meta: problem_45and_45solutionzMmJ7jxMVjMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/problem-and-solution.vue")
  },
  {
    name: "token-designer-token-profile-product-market-fit",
    path: "/token-designer/token-profile/product-market-fit",
    meta: product_45market_45fitKmPmji1IspMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/product-market-fit.vue")
  },
  {
    name: "token-designer-token-profile-value-capture",
    path: "/token-designer/token-profile/value-capture",
    meta: value_45captureafsCE8IPf1Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/value-capture.vue")
  },
  {
    name: "token-designer-token-profile-value-creation",
    path: "/token-designer/token-profile/value-creation",
    meta: value_45creationnqKWZH8uKsMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/value-creation.vue")
  },
  {
    name: "token-designer-valuation-documenting-fdv-maximum-token-supply",
    path: "/token-designer/valuation-documenting/fdv-maximum-token-supply",
    meta: fdv_45maximum_45token_45supplyhP8ge5J5rgMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/valuation-documenting/fdv-maximum-token-supply.vue")
  },
  {
    name: "token-designer-valuation-documenting-sanitizing-tokenomics",
    path: "/token-designer/valuation-documenting/sanitizing-tokenomics",
    meta: sanitizing_45tokenomicsD4w2tsPzS2Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/valuation-documenting/sanitizing-tokenomics.vue")
  },
  {
    name: "token-settings",
    path: "/token-settings",
    meta: token_45settingsPDUhAPfihbMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/dashboard/pages/token-settings.vue")
  },
  {
    name: "token-unlocks",
    path: "/token-unlocks",
    meta: indexABpPLza0lUMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-unlocks/index.vue")
  },
  {
    name: "tokenomics",
    path: "/tokenomics",
    meta: indexaB6DKjLij0Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/tokenomics/index.vue")
  },
  {
    name: "v2-authorization",
    path: "/v2/authorization",
    meta: authorizationrgPABm5vD5Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/onboarding/pages/v2/authorization.vue")
  },
  {
    name: "v2-invitation-code",
    path: "/v2/invitation/:code()",
    meta: _91code_930KenkwevttMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/onboarding/pages/v2/invitation/[code].vue")
  },
  {
    name: "verify-email",
    path: "/verify-email",
    meta: indexP3TFTIKcavMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/verify-email/index.vue")
  },
  {
    name: component_45stubumNe5hscdmMeta?.name,
    path: "/privacy-policy",
    component: component_45stubumNe5hscdm
  },
  {
    name: component_45stubumNe5hscdmMeta?.name,
    path: "/terms-of-use",
    component: component_45stubumNe5hscdm
  },
  {
    name: component_45stubumNe5hscdmMeta?.name,
    path: "/",
    component: component_45stubumNe5hscdm
  }
]