import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { MarketMakerPathParam, MarketMakerResult } from '../types'

const c = initContract()

export const liquidityMarketMakerContract = c.router({
  getEngagedProjects: {
    method: 'GET',
    path: '/liquidity-market-maker/engaged-projects',
    responses: {
      200: z.array(
        z.object({
          projectId: z.string().uuid(),
          projectName: z.string(),
          imageUrl: z.string().nullable(),
        }),
      ),
    },
    summary: 'Get market maker engaged projects',
  },
  getMarketMaker: {
    method: 'GET',
    path: '/liquidity-market-maker/:marketMakerId',
    pathParams: MarketMakerPathParam,
    responses: {
      200: MarketMakerResult,
    },
    summary: 'Get market maker by Id.',
  },
})

export type LiquidityMarketMakerContractType = typeof liquidityMarketMakerContract
