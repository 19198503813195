import { initContract } from '@ts-rest/core'
import { z } from 'zod'

const c = initContract()

export const authContract = c.router({
  resetPassword: {
    method: 'POST',
    path: '/auth/reset-password',
    responses: {
      204: z.undefined(),
    },
    body: z.object({
      email: z.string().email().toLowerCase(),
    }),
    summary: 'Send reset password email',
  },
})

export type AuthContractType = typeof authContract
