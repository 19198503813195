export const RFQKPISummaryFilterOptionEnum = {
  AllReceivedQuotes: 'all_received_quotes',
  AllSelectedAverage: 'all_selected_average',
  MyQuotesAverage: 'my_quotes_average',
} as const

export const RFQKPISummaryFilterOptionEnumValues = [
  'all_received_quotes',
  'all_selected_average',
  'my_quotes_average',
] as const

export type RFQKPISummaryFilterOptionEnumType =
  (typeof RFQKPISummaryFilterOptionEnum)[keyof typeof RFQKPISummaryFilterOptionEnum]

export const ActiveRFQStatusFilterOptionEnum = {
  All: 'all',
  AcceptingQuotes: 'accepting_quotes',
  InReviewRFQs: 'in_review_rfqs',
  ParticipatingRFQs: 'participating_rfqs',
} as const

export const ActiveRFQStatusFilterOptionEnumValues = [
  'all',
  'accepting_quotes',
  'in_review_rfqs',
  'participating_rfqs',
] as const

export type ActiveRFQStatusFilterOptionEnumType =
  (typeof ActiveRFQStatusFilterOptionEnum)[keyof typeof ActiveRFQStatusFilterOptionEnum]

export const ClosedRFQStatusFilterOptionEnum = {
  All: 'all',
  ParticipatedAndSelected: 'participated_and_selected',
  ParticipatedAndRejected: 'participated_and_rejected',
  IgnoredRFQs: 'ignored',
} as const

export const ClosedRFQStatusFilterOptionEnumValues = [
  'all',
  'participated_and_selected',
  'participated_and_rejected',
  'ignored',
] as const

export type ClosedRFQStatusFilterOptionEnumType =
  (typeof ClosedRFQStatusFilterOptionEnum)[keyof typeof ClosedRFQStatusFilterOptionEnum]

export const MMLeaderboardFilterByOptionEnum = {
  All: 'all',
  Depth: 'depth',
  Volume: 'volume',
  Spread: 'spread',
} as const

export const MMLeaderboardFilterByOptionEnumValues = ['all', 'depth', 'volume', 'spread'] as const

export type MMLeaderboardFilterByOptionEnumType =
  (typeof MMLeaderboardFilterByOptionEnum)[keyof typeof MMLeaderboardFilterByOptionEnum]

export const LiquidityTypeOptionEnum = {
  Ask: 'ask',
  Bid: 'bid',
  BidAsk: 'bidAsk',
} as const

export const LiquidityTypeOptionEnumValues = ['ask', 'bid', 'bidAsk'] as const

export type LiquidityTypeOptionEnumType = (typeof LiquidityTypeOptionEnum)[keyof typeof LiquidityTypeOptionEnum]

export const DepthLevelOptionEnum = {
  Depth50: '50',
  Depth100: '100',
  Depth200: '200',
} as const

export const DepthLevelOptionEnumValues = ['50', '100', '200'] as const

export type DepthLevelOptionEnumType = (typeof DepthLevelOptionEnum)[keyof typeof DepthLevelOptionEnum]

export const RankValueOptionEnum = {
  Dollar: 'value_dollar',
  Percent: 'value_percent',
} as const

export const RankValueOptionEnumValues = ['value_dollar', 'value_percent'] as const

export type RankValueOptionEnumType = (typeof RankValueOptionEnum)[keyof typeof RankValueOptionEnum]

export const RankPresentationOptionEnum = {
  Rank: 'rank',
  Values: 'values',
} as const

export const RankPresentationOptionEnumValues = ['rank', 'values'] as const

export type RankPresentationOptionEnumType =
  (typeof RankPresentationOptionEnum)[keyof typeof RankPresentationOptionEnum]

export const LikertScaleEnum = {
  StronglyDisagree: 1,
  Disagree: 2,
  Neutral: 3,
  Agree: 4,
  StronglyAgree: 5,
} as const

export type LikertScaleEnumType = (typeof LikertScaleEnum)[keyof typeof LikertScaleEnum]
