import { initContract } from '@ts-rest/core'
import { type ProjectsContractType, projectsContract } from './projects'
import { type BusinessIntelligenceContractType, businessIntelligenceContract } from './business-intelligence'
import { type TokenDesignerContractType, tokenDesignerContract } from './token-designer'
import { type TokenDesignerValuationContractType, tokenDesignerValuationContract } from './token-designer.valuation'
import { type TokenDesignerCalculatorContractType, tokenDesignerCalculatorContract } from './token-designer.calculator'
import { type TokenDesignerPublicPageContractType, tokenDesignerPublicPage } from './token-designer.public'
import { type MarketMakerContractType, marketMakerContract } from './market-maker'
import {
  type LiquidityMonitoringForMarketMakerContractType,
  liquidityMonitoringforMarketMakerContract as LiquidityMonitoringForMarketMakerContract,
} from './liquidity-monitoring.for-mmaker'
import {
  type LiquidityMonitoringForProjectContractType,
  liquidityMonitoringForProjectContract as LiquidityMonitoringForProjectContract,
} from './liquidity-monitoring.for-project'
import { type UsersContractType, usersContract } from './users'
import { type CoinGeckoContractType, coinGeckoContract } from './coin-gecko'
import { activitiesContract, type ActivitiesContractType } from './activities'
import { type MembershipContractType, membershipContract } from './membership'
import { onboardingContract, type OnboardingType } from './onboarding'
import { tokenUnlockContract, type TokenUnlockContractType } from './token-unlock'
import { marketMakerRFQContract, type MarketMakerRFQContractType } from './market-maker-rfq'
import { exchangeListingContract, type ExchangeListingContractType } from './exchange-listing'
import { tracingContract, type TracingContractType } from './tracing'
import { liquidityContract, type LiquidityContractType } from './liquidity'
import { liquidityMarketMakerContract, type LiquidityMarketMakerContractType } from './liquidity-market-maker'
import { authContract, type AuthContractType } from './auth'

const c = initContract()

type ContractType = {
  auth: AuthContractType
  activities: ActivitiesContractType
  businessIntelligence: BusinessIntelligenceContractType
  coinGecko: CoinGeckoContractType
  exchangeListing: ExchangeListingContractType
  liquidity: LiquidityContractType
  liquidityMarketMaker: LiquidityMarketMakerContractType
  liquidityMonitoringForProject: LiquidityMonitoringForProjectContractType
  liquidityMonitoringForMarketMaker: LiquidityMonitoringForMarketMakerContractType
  marketMaker: MarketMakerContractType
  marketMakerRFQ: MarketMakerRFQContractType
  membership: MembershipContractType
  onboarding: OnboardingType
  projects: ProjectsContractType
  tokenDesigner: TokenDesignerContractType
  tokenDesignerCalculator: TokenDesignerCalculatorContractType
  tokenDesignerPublicPage: TokenDesignerPublicPageContractType
  tokenDesignerValuation: TokenDesignerValuationContractType
  tokenUnlock: TokenUnlockContractType
  tracing: TracingContractType
  users: UsersContractType
}

export const contract: ContractType = c.router({
  auth: authContract,
  activities: activitiesContract,
  businessIntelligence: businessIntelligenceContract,
  coinGecko: coinGeckoContract,
  exchangeListing: exchangeListingContract,
  liquidity: liquidityContract,
  liquidityMarketMaker: liquidityMarketMakerContract,
  liquidityMonitoringForProject: LiquidityMonitoringForProjectContract,
  liquidityMonitoringForMarketMaker: LiquidityMonitoringForMarketMakerContract,
  marketMaker: marketMakerContract,
  marketMakerRFQ: marketMakerRFQContract,
  membership: membershipContract,
  onboarding: onboardingContract,
  projects: projectsContract,
  tokenDesigner: tokenDesignerContract,
  tokenDesignerCalculator: tokenDesignerCalculatorContract,
  tokenDesignerPublicPage: tokenDesignerPublicPage,
  tokenDesignerValuation: tokenDesignerValuationContract,
  tokenUnlock: tokenUnlockContract,
  tracing: tracingContract,
  users: usersContract,
})
