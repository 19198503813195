<script setup lang="ts">
import { chartColors } from '#core/utils/charts'
import { VisLine, VisXYContainer } from '@unovis/vue'

type Datum = Record<string, number>

interface Props {
  data: Datum[]
  xAxis?: string
  yAxis?: string
  width?: string | number
  height?: string | number
  color?: string
}

/**
 * UiSparkline
 *
 * Bare Unovis line chart to visualize chart data as a sparkline
 *
 * - attempts to guess axes
 * - handles string time axis
 */
const props = withDefaults(defineProps<Props>(), {
  xAxis: guessAxis(['x', 'index', 'timestamp', 'date'], 0),
  yAxis: guessAxis(['y', 'value', 1], 1),
  width: 60,
  height: 30,
})

const trend = computed<-1 | 0 | 1>(() => {
  const first = props.data.at(0)[props.yAxis]
  const last = props.data.at(-1)[props.yAxis]
  const value = last - first
  return value > 0
    ? 1
    : value < 0
      ? -1
      : 0
})

const color = computed(() => {
  if (props.color) {
    return props.color
  }
  const key = trend.value
  return [
    chartColors.trend[key],
  ]
})

function getX(datum: Record<string, number | string>, index: number): number {
  const value = datum[props.xAxis]
  return typeof value === 'number'
    ? value
    : index
}

function getY(datum: Record<string, number | string>): number {
  return datum[props.yAxis]
}
</script>

<script lang="ts">
function guessAxis(keys: string[], index: number): Datum {
  return (props: Record<string, any>) => {
    const d = props.data.at(0)
    for (const key of keys) {
      if (key in d) {
        return key
      }
    }
    return Object.keys(d).at(index)
  }
}
</script>

<template>
  <div data-ui="UiSparkline">
    <VisXYContainer :data="data" :width="width" :height="height">
      <VisLine :x="getX" :y="getY" :color="color" duration="0" line-width="1.5" />
    </VisXYContainer>
  </div>
</template>

<style lang="scss">
div[data-ui="UiSparkline"] {
  // hide the "helper" line
  path[class*="lineSelectionHelper"] {
    display: none !important;
  }
}
</style>
