import { z } from 'zod'
import { EngagementOptionEnumValues, MarketMakerEngagementsStatusEnumValues } from '@forgd/supabase'
import { MarketMakerSchema } from '../market-maker'

// Engagement Schema
const EngagementSchema = z.object({
  id: z.string(),
  projectId: z.string(),
  qtyTokensLoan: z.number(),
  qtyTokensWorkingCapital: z.number(),
  qtyStablesWorkingCapital: z.number(),
  bidAskSpreadTarget: z.number(),
  depth50Target: z.number(),
  depth100Target: z.number(),
  depth200Target: z.number(),
  uptimeSpread: z.number(),
  uptimeBidAskDepth50: z.number(),
  uptimeBidAskDepth100: z.number(),
  uptimeBidAskDepth200: z.number(),
  marketMakerId: z.string(),
  status: z.enum(MarketMakerEngagementsStatusEnumValues),
  type: z.enum(EngagementOptionEnumValues),
  importName: z.string().nullable(),
})

// Get Engagements
export const GetEngagementsResult = z.array(
  EngagementSchema.extend({
    marketMaker: MarketMakerSchema,
  }),
)
export type GetEngagementsResultType = z.infer<typeof GetEngagementsResult>

// Post Engagements (create a new) inherits EngagementSchema
export const PostEngagementSchema = EngagementSchema.pick({
  projectId: true,
  type: true,
  marketMakerId: true,
})

export const PostEngagementsSchema = z.array(PostEngagementSchema)
export type PostEngagementsSchemaType = z.infer<typeof PostEngagementsSchema>

// Patch Engagement Schema (update engagement KPI's)
export const PatchEngagementsSchema = z.array(
  EngagementSchema.pick({
    id: true,
    qtyTokensLoan: true,
    qtyTokensWorkingCapital: true,
    qtyStablesWorkingCapital: true,
    bidAskSpreadTarget: true,
    depth50Target: true,
    depth100Target: true,
    depth200Target: true,
    uptimeSpread: true,
    uptimeBidAskDepth50: true,
    uptimeBidAskDepth100: true,
    uptimeBidAskDepth200: true,
    type: true,
  }),
)
export type PatchEngagementsSchemaType = z.infer<typeof PatchEngagementsSchema>
