<script setup lang="ts">
import type { IconClass } from '#core/types'

withDefaults(defineProps<{
  name?: IconClass
  size?: keyof typeof sizes
  color?: keyof typeof colors
}>(), {
  name: 'i-heroicons-check-circle',
  size: 'md',
})

const sizes = {
  'sm': 'size-3',
  'md': 'size-4',
  'lg': 'size-5',
  'xl': 'size-6',
  '2xl': 'size-8',
  '3xl': 'size-10',
  '4xl': 'size-16',
  '5xl': 'size-24',
  'full': 'min-w-24 min-h-24 size-full',
}
const colors = {
  primary: `  text-primary-300`,
  green: `    text-green-600`,
  yellow: `   text-yellow-700`,
  red: `      text-red-500`,
  gray: `     text-slate-600`,
  muted: `    text-neutral-700`,
}
</script>

<template>
  <i data-ui="UiIcon" class="shrink-0" :class="[name, sizes[size], color && colors[color]]" />
</template>
