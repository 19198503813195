import { withBase } from 'ufo'

export const FALLBACK_MM_IMAGE_URL = '/images/market-maker-logos/other.png'
export const FALLBACK_EXCHANGE_IMAGE_URL = '/images/market-maker-logos/exchange-other.jpg'
export const FALLBACK_PROJECT_IMAGE_URL = '/icons/project-placeholder-icon.svg'

export function resolveLogoWithFallback(bucket: 'market-maker' | 'exchange' | 'project', path?: string | null) {
  // we can only resolve paths if they're relative from the page
  if (path && (path.startsWith('http') || path.startsWith('/'))) {
    return path
  }
  switch (bucket) {
    case 'market-maker':
      if (!path) {
        return FALLBACK_MM_IMAGE_URL
      }
      return withBase(path, `/images/market-maker-logos/`)
    case 'exchange':
      if (!path) {
        return FALLBACK_EXCHANGE_IMAGE_URL
      }
      return withBase(path, `/images/exchanges/`)
    case 'project':
      if (!path) {
        return FALLBACK_PROJECT_IMAGE_URL
      }
      return path
    default:
      return path || FALLBACK_PROJECT_IMAGE_URL
  }
}
