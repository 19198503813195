<script setup lang="ts">
import { getContent, loadContent, type Options } from '../composables/useAcademyContent'

const props = defineProps<Options>()

const el = ref<HTMLElement | null>(null)
const loadingContent = await loadContent(props)
const content = await getContent(props, loadingContent)

/**
 * Prevent links opening in the same page
 */
function handleClicks(el: Ref<HTMLElement | null>) {
  const onClick = (event: MouseEvent) => {
    const target = event.target as HTMLAnchorElement
    if (target.getAttribute('target') !== '_blank') {
      event.preventDefault()
    }
  }

  onMounted(() => {
    el.value?.addEventListener('click', onClick)
  })

  onUnmounted(() => {
    el.value?.removeEventListener('click', onClick)
  })
}

onMounted(() => {
  handleClicks(el)
})
</script>

<template>
  <article ref="el" data-ui="UiContent" class="nuxt-content">
    <UiLoading v-if="!content" type="spinner" size="md" loading />
    <ContentRenderer v-else :value="content">
      <ContentRendererMarkdown :value="content" />
    </ContentRenderer>
  </article>
</template>
