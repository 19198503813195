<script lang="ts" setup>
import type { ProjectDashboardMetrics } from '@forgd/contract/types'
import { UiSparkline, UiTrend } from '#components'
import { differenceInDays } from 'date-fns'

const props = defineProps<{
  projectId: string
  loading: boolean
  metrics?: ProjectDashboardMetrics
}>()

const { publicPageDomain } = useRuntimeConfig().public

const headerTitle = computed(() => {
  if (props.metrics?.isTokenListed) {
    return `Optimize Post-TGE Growth for ${props.metrics?.projectTicker.toUpperCase() || props.metrics?.projectName}`
  }
  return `Prepare for ${props.metrics?.projectTicker.toUpperCase() || props.metrics?.projectName}’s Launch from your pre-TGE Dashboard`
})

const headerText = computed(() => {
  if (props.metrics?.isTokenListed) {
    return 'Monitor your token’s performance and refine your growth strategy with our suite of tools. Leverage actionable insights to sustain your market presence & catalyze protocol growth.'
  }
  return 'Streamline your path to a successful TGE with essential tools and data. Use this dashboard to plan, execute, and manage pre-launch activities seamlessly.'
})

const date = computed(() => props.metrics?.tgeDate ? new Date(props.metrics.tgeDate) : undefined)
const isPreTge = computed(() => !props.metrics?.isTokenListed)
const formattedDate = computed(() => formatValue(date.value, 'date'))
const daysUntilTge = computed(() => {
  if (!date.value) {
    return undefined
  }

  if (isPreTge.value) {
    return differenceInDays(date.value, new Date())
  }
  return differenceInDays(new Date(), date.value)
})

const Sparkline = computed(() => props.metrics?.tokenPrices?.marketChartPrice24h
  ? () => h(UiSparkline, { data: props.metrics?.tokenPrices?.marketChartPrice24h ?? [], height: 20, width: 40 })
  : undefined)

const Trend = computed(() => props.metrics?.tokenPrices?.priceChange24h
  ? () => h(UiTrend, { value: props.metrics?.tokenPrices?.priceChange24h ?? 0, format: 'percent:3', class: 'font-semibold' })
  : undefined)

const visiblePublicPageUrl = computed(() => `https://${publicPageDomain}/share/${props.metrics?.projectTicker.toLowerCase()}...`)
const publicPageUrl = computed(() => `https://${publicPageDomain}/share/${props.metrics?.projectTicker.toLowerCase()}/${props.projectId}/public`)
const mmIcon = computed(() => {
  switch (props.metrics?.mmRfqStatus) {
    case 'new':
      return 'i-heroicons-lock-closed'
    case 'round_one_waiting_for_quotes':
    case 'round_one_quotes_in_review':
      return 'i-heroicons-lock-open'
    case 'round_two_waiting_for_quotes':
    case 'round_two_quotes_in_review':
      return 'i-heroicons-lock-open'
    case 'round_one_quotes_accepted':
    case 'round_two_quotes_accepted':
      return 'i-heroicons-check-circle'
    default:
      return 'i-heroicons-lock-closed'
  }
})
const mmIconClass = computed(() => {
  if (props.metrics?.mmRfqStatus === 'round_one_quotes_accepted'
    || props.metrics?.mmRfqStatus === 'round_two_quotes_accepted') {
    return 'text-green-600'
  }
  return 'text-forgd-accent-300'
})
const mmText = computed(() => {
  switch (props.metrics?.mmRfqStatus) {
    case 'new':
      return 'Not Started'
    case 'round_one_waiting_for_quotes':
    case 'round_one_quotes_in_review':
      return 'In Round 1'
    case 'round_two_waiting_for_quotes':
    case 'round_two_quotes_in_review':
      return 'In Round 2'
    case 'round_one_quotes_accepted':
    case 'round_two_quotes_accepted':
      return 'Closed'
    default:
      return 'Not Started'
  }
})
const mmEngagementLogos = computed(() => {
  return (props.metrics?.mmEngagements ?? [])
    .map(engagement => resolveLogoWithFallback('market-maker', engagement.imageUrl))
    .filter((url): url is string => url !== null)
})

const mmRoundReceivedQuotes = computed(() => {
  if (!props.metrics?.mmRfqStatus) {
    return 0
  }
  switch (props.metrics?.mmRfqStatus) {
    case 'new':
      return 0
    case 'round_one_waiting_for_quotes':
    case 'round_one_quotes_in_review':
    case 'round_one_quotes_accepted':
      return props.metrics?.mmReceivedQuotes?.round1 ?? 0
    case 'round_two_waiting_for_quotes':
    case 'round_two_quotes_in_review':
    case 'round_two_quotes_accepted':
      return props.metrics?.mmReceivedQuotes?.round2 ?? 0
    default:
      return 0
  }
})

const mmLastReportedAt = computed(() => formatValue(props.metrics?.mmRfqLastReportedAt, 'date'))
const tokenBaseLayers = computed(() =>
  (props.metrics?.baseLayer || [])
    .filter(layer => (layer in tokenLayers))
    .map(layer => tokenLayers[layer].src),
)

function goToTokenSettings() {
  navigateTo('/token-settings')
}

function handlePublicTokenPageAction() {
  if (!props.metrics?.isTokenListed) {
    navigateTo('/token-designer/valuation-documenting/sanitizing-tokenomics')
    return
  }
  window.open(publicPageUrl.value, '_blank')
}

function handleMmRfqStatusAction() {
  let routePath = '/engage-a-market-maker'

  switch (props.metrics?.mmRfqStatus) {
    case 'round_one_waiting_for_quotes':
    case 'round_one_quotes_in_review':
    case 'round_one_quotes_accepted':
      routePath = '/engage-a-market-maker/received-quotes'
      break
    case 'round_two_waiting_for_quotes':
    case 'round_two_quotes_in_review':
    case 'round_two_quotes_accepted':
      routePath = '/engage-a-market-maker/received-requotes'
      break
  }

  navigateTo(routePath)
}

function handleMmEngagementsAction() {
  navigateTo('/market-maker-monitoring')
}

function handleLinkCoinGeckoId() {
  navigateTo('/token-settings#coingecko')
}
</script>

<template>
  <div class="space-y-10">
    <UiHeader :title="headerTitle" :text="headerText" />
    <div class="grid grid-cols-4 gap-4">
      <UiStat
        variant="card"
        :loading="loading"
        :title="isPreTge ? 'Est. TGE Date' : 'TGE Date'"
        :value="formattedDate"
        :info="isPreTge ? 'Days Until TGE' : 'Days Since TGE'"
        :meta="daysUntilTge"
        action="i-heroicons-pencil"
        action-tooltip="Edit"
        @action="goToTokenSettings"
      >
        <template v-if="daysUntilTge && daysUntilTge < 0" #info>
          <span class="text-red-600">Your TGE date needs attention</span>
        </template>
      </UiStat>
      <template v-if="metrics?.isTokenListed">
        <UiStat
          v-if="metrics?.tokenPrices"
          variant="card"
          :loading="loading"
          :title="`${metrics?.projectTicker} Price (USD)`"
          format="currency"
          :value="metrics?.tokenPrices?.price ?? 0"
          info="24h Change"
          :right="Sparkline"
          :meta="Trend"
        />
        <UiStat
          v-else
          variant="card"
          :loading="loading"
          :title="`${metrics?.projectTicker} Price (USD)`"
          value="Not Available"
          info="Link your CoinGecko ID"
          action="i-heroicons-chevron-right"
          action-tooltip="Link CoinGecko ID"
          @action="handleLinkCoinGeckoId"
        />
      </template>
      <UiStat
        v-else
        variant="card"
        :loading="loading"
        title="Public Token Page"
        tooltip="This page contains all the project details added in Token Designer. Use this page to share your tokenomics with core contributors and investors."
        :action="metrics?.isPublicPagePublished ? 'i-heroicons-arrow-up-right' : 'i-heroicons-chevron-right'"
        action-tooltip="Open Page"
        @action="handlePublicTokenPageAction"
      >
        <template #value>
          <div class="flex flex-row items-center gap-1 truncate">
            <UiIcon
              :name="metrics?.isPublicPagePublished ? 'i-heroicons-globe-alt' : 'i-heroicons-eye-slash'"
              class="text-forgd-accent-300"
              size="md"
            />
            <div class="font-display overflow-hidden font-semibold text-xl/5 text-primary-900">
              {{ metrics?.isPublicPagePublished ? 'Published' : 'Not Published' }}
            </div>
          </div>
        </template>
        <template #info>
          <NuxtLink v-if="metrics?.isPublicPagePublished" :href="publicPageUrl" target="_blank" class="text-forgd-accent-300 truncate">
            {{ visiblePublicPageUrl }}
          </NuxtLink>
          <template v-else>
            Edit in Token Designer
          </template>
        </template>
      </UiStat>

      <UiStat
        v-if="metrics?.isTokenListed && mmEngagementLogos.length > 0"
        variant="card"
        :loading="loading"
        title="Market Maker Engagements"
        info="Last Report"
        :meta="mmLastReportedAt"
        action="i-heroicons-chevron-right"
        action-tooltip="See Details"
        @action="handleMmEngagementsAction"
      >
        <template #value>
          <div class="flex flex-row items-center gap-1 truncate">
            <UiAvatar :src="mmEngagementLogos" size="xs" />
            <div class="font-display overflow-hidden font-semibold text-xl/5 text-primary-900">
              {{ mmEngagementLogos.length }} Active
            </div>
          </div>
        </template>
      </UiStat>

      <UiStat
        v-else
        variant="card"
        :loading="loading"
        title="Market Maker RFQ Status"
        tooltip="This card shows data related on the progression of any request for quote (“RFQ”) to market makers to support liquidity for your native token."
        info="Total Received Quotes"
        :meta="mmRoundReceivedQuotes"
        action="i-heroicons-chevron-right"
        action-tooltip="See Details"
        @action="handleMmRfqStatusAction"
      >
        <template #value>
          <div class="flex flex-row items-center gap-1 truncate">
            <UiIcon
              :name="mmIcon"
              :class="mmIconClass"
              size="md"
            />
            <div class="font-display overflow-hidden font-semibold text-xl/5 text-primary-900">
              {{ mmText }}
            </div>
          </div>
        </template>
      </UiStat>

      <UiStat
        variant="card"
        :loading="loading"
        title="Token Base Layer"
        info="Active Teammates"
        :meta="metrics?.activeMembers"
        action="i-heroicons-chevron-right"
        action-tooltip="See Details"
        @action="goToTokenSettings"
      >
        <template #value>
          <div class="flex flex-row items-center gap-1 truncate">
            <UiAvatar :src="tokenBaseLayers" size="xs" :max="3" />
            <div class="font-display overflow-hidden font-semibold text-xl/5 text-primary-900">
              {{ tokenBaseLayers.length }} Selected
            </div>
          </div>
        </template>
      </UiStat>
    </div>
  </div>
</template>
