import { z } from 'zod'
import { TProject } from '../projects'
import { BaseLayerTypeEnumValues, MemberStatusEnumValues, SectorTypeEnumValues } from '@forgd/supabase'

export const UserResponse = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  isOnboarded: z.boolean(),
  telegram: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
  isGuidedTourEnabled: z.boolean(),
  organizations: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      ownerUserId: z.string(),
      createdAt: z.date(),
      updatedAt: z.date(),
      deletedAt: z.date().nullable(),
    }),
  ),
})

const GetMeProject = TProject.omit({
  areasOfInterest: true,
}).extend({
  organizationId: z.string().uuid().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
  tgeDate: z.string().date().nullable(),
  baseLayer: z.array(z.enum(BaseLayerTypeEnumValues)).nullable(),
  sector: z.array(z.enum(SectorTypeEnumValues)).nullable(),
  isOnboarded: z.boolean(),
})

const GetMeOrganization = z.object({
  id: z.string(),
  name: z.string(),
  ownerUserId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
  projects: z.array(GetMeProject),
  membersCount: z.number(),
  memberStatus: z.enum(MemberStatusEnumValues),
})

export const GetMeResponse = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  isOnboarded: z.boolean(),
  telegram: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
  isGuidedTourEnabled: z.boolean(),
  organizations: z.array(GetMeOrganization),
})

export type UserMeResponse = z.infer<typeof GetMeResponse>
export type OrganizationMeResponse = z.infer<typeof GetMeOrganization>
export type ProjectMeResponse = z.infer<typeof GetMeProject>
