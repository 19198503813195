import { z } from 'zod'
import { LeadSourceTypeEnumValues } from '@forgd/supabase'

export const TUpdateUser = z.object({
  firstName: z.string().nullable().optional(),
  lastName: z.string().nullable().optional(),
  telegram: z.string().nullable().optional(),
  leadSource: z.enum(LeadSourceTypeEnumValues).nullable().optional(),
  isGuidedTourEnabled: z.boolean().optional(),
})
