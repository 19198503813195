import unhead_94hBaKU3IH from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.38.4_@opentel_sysu2verttu62bk247oexq2hca/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ydb00iIamW from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.38.4_@opentel_sysu2verttu62bk247oexq2hca/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_0Xg0t7ySbj from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_gGiDY74OUp from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.38.4_@opentel_sysu2verttu62bk247oexq2hca/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_H1NLh1kAx5 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.38.4_@opentel_sysu2verttu62bk247oexq2hca/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_oRdhN21pN9 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.38.4_@opentel_sysu2verttu62bk247oexq2hca/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Sd55vUMBhT from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.38.4_@opentel_sysu2verttu62bk247oexq2hca/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_GuQ4aLTsQ1 from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.29.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/ui/.nuxt/components.plugin.mjs";
import prefetch_client_6BtNv213cE from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.38.4_@opentel_sysu2verttu62bk247oexq2hca/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_fq3XqjlMHd from "/opt/render/project/src/node_modules/.pnpm/@nuxthq+studio@2.2.1_magicast@0.3.5_rollup@4.29.1/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import plugin_6PNiRPrm2R from "/opt/render/project/src/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.29.1_typescript@5_u6rdn6qt6phjh5ssgq4e72axoa/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import slideovers_0WA6JeCyuA from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.29.1_v_mukwyymramkrelbavewgn2nly4/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_lZyZR4QZV4 from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.29.1_v_mukwyymramkrelbavewgn2nly4/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_OaX5OqDcWv from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.29.1_v_mukwyymramkrelbavewgn2nly4/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_iTMze7Qqk7 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.29.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_JKkHw6Tw8i from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.3_sass-embedded@_pdjmnnrk3tsqp3mgusztc2gjpi/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_hFtICnrvkf from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.29._dkvhintcad2ql7552bvexqg36y/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_4cuzMxGqQq from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.29._dkvhintcad2ql7552bvexqg36y/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_1xntqj0dyQ from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.29._dkvhintcad2ql7552bvexqg36y/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import search_aUu6HJpKV6 from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/academy/plugins/search.ts";
import lottie_AzpYEGWRZG from "/opt/render/project/src/libs/nuxt-forgd-layer-core/plugins/lottie.ts";
import sentry_client_3lKG8ZOviM from "/opt/render/project/src/libs/nuxt-forgd-layer-core/plugins/sentry.client.ts";
import tracing_client_csePbkLi2Z from "/opt/render/project/src/libs/nuxt-forgd-layer-core/plugins/tracing.client.ts";
import access_client_4oVQ9ZBHtd from "/opt/render/project/src/apps/ui/plugins/access.client.ts";
export default [
  unhead_94hBaKU3IH,
  router_Ydb00iIamW,
  supabase_client_0Xg0t7ySbj,
  payload_client_gGiDY74OUp,
  navigation_repaint_client_H1NLh1kAx5,
  check_outdated_build_client_oRdhN21pN9,
  chunk_reload_client_Sd55vUMBhT,
  plugin_vue3_GuQ4aLTsQ1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6BtNv213cE,
  preview_client_fq3XqjlMHd,
  plugin_6PNiRPrm2R,
  slideovers_0WA6JeCyuA,
  modals_lZyZR4QZV4,
  colors_OaX5OqDcWv,
  plugin_client_iTMze7Qqk7,
  plugin_JKkHw6Tw8i,
  scrollbars_client_hFtICnrvkf,
  presets_4cuzMxGqQq,
  variables_1xntqj0dyQ,
  search_aUu6HJpKV6,
  lottie_AzpYEGWRZG,
  sentry_client_3lKG8ZOviM,
  tracing_client_csePbkLi2Z,
  access_client_4oVQ9ZBHtd
]