import type { AcademyLink } from '#academy-virtual/types'
import type { MarkdownNode, ParsedContent } from '@nuxt/content/types'

export interface Options {
  content?: ParsedContent
  path?: AcademyLink
}

const emptyContent: ParsedContent = {
  _type: 'markdown',
  _id: '',
  _empty: false,
  description: '',
  body: {
    type: 'root',
    children: [],
  },
}

async function loadContent(options: Options): Promise<ParsedContent | null> {
  const [article] = (options.path as string || '').split('#')
  return options.content || await (queryContent(article).findOne()).catch((err) => {
    console.warn('queryContent() error:', err)
    return Promise.resolve(null)
  })
}

/**
 * Loads and returns an article or a section of an article
 */
async function getContent(options: Options, content: ParsedContent | null): Promise<ParsedContent> {
  const [article, section] = (options.path as string || '').split('#')
  // article not found
  if (!content) {
    return makeContent(emptyContent, `Invalid article path "${article}"`)
  }

  // return full article
  if (!section) {
    useContentHead(content)
    return makeContent(content, content.body!.children)
  }

  // return section only
  let start = null
  let end = null

  if (content.body?.children && content.body?.children.length > 0) {
    for (const [index, child] of content.body?.children.entries()) {
      if ((child.tag === 'h2' || child.tag === 'h3') && child.props?.id === section) {
        start = index
        continue
      }

      if (start && child.tag === 'h2') {
        end = index
        break
      }
    }
  }
  else {
    return makeContent(emptyContent, 'Empty document')
  }

  // section found
  if (start) {
    return makeContent(content, content.body.children.slice(start, end || undefined))
  }

  // section not found
  const ids = content.body.children.values().map(child => child.props?.id)

  // eslint-disable-next-line no-console
  console.info(`The article "${article}" contains only these headings:`, ids)
  return makeContent(content, `The section "${section}" was not found in this article (see console for details).`)
}

/**
 * Walk through the Markdown nodes and fix the following issues with links:
 * - change anchor links to absolute links
 * - add target="_blank"
 * - update img href url to work with public folder
 */
function walk(nodes: MarkdownNode[], path: string) {
  for (const node of nodes) {
    if (node.type === 'element') {
      if (node.tag === 'a') {
        // fix anchor links
        const href = node.props?.href?.split('#')[0]
        if (path.includes(href)) {
          node.props!.href = `${path}#${node.props!.href?.split('#')[1]}`
        }

        // add target="_blank"
        if (node.props?.target !== '_blank') {
          node.props = {
            ...node.props,
            target: '_blank',
          }
        }
      }
      else if (node.tag === 'img') {
        const src = node.props?.src
        if (src && src.startsWith('./')) {
          node.props!.src = `${window.location.origin}/${src}`
        }
      }
    }
    if (node.children) {
      walk(node.children, path)
    }
  }
  return nodes
}

function makeContent(content: ParsedContent, newBody: string | MarkdownNode[]): ParsedContent {
  let children: MarkdownNode[]
  if (typeof newBody === 'string') {
    children = [
      {
        type: 'element',
        tag: 'p',
        props: {},
        children: [
          { type: 'text', value: newBody },
        ],
      },
    ]
  }
  else {
    children = newBody
  }

  return {
    ...content,
    body: {
      type: 'root',
      children: walk(children, content._path!),
    },
  }
}

export { getContent, loadContent, makeContent }
