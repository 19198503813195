import type { QuotesByEngagementType, QuotesSelectionPreview, RFQDetails, RFQRoundDetails } from '@forgd/contract/types'

import { FORGD_SYSTEM_PROJECT_ID } from '@forgd/contract/constants'
import { MAX_DAYS_FOR_USER_TO_MAKE_DECISION_ON_QUOTES, MAX_SELECTED_QUOTES } from '@forgd/contract/data'
import { MarketMakerRequestStatusEnum } from '@forgd/supabase'
import { addDays, parse } from 'date-fns'

const RFQ_NAV_ITEM = 'Request for Quotations'
const ROUND_1_NAV_ITEM = 'Round-1 Received Quotes'
const ROUND_2_NAV_ITEM = 'Round-2 Received Requotes'
interface RoundInfo {
  type: 'accepted' | 'requoted' | 'new'
  dateISO: string
  round?: number
}

export type NavItemStatus = 'none' | 'lock' | 'available' | 'completed' | 'requoted'

interface NavItem {
  label: string
  link: string
  status: NavItemStatus
}

export const useRFQDetails = defineStore('engage-a-market-maker', () => {
  const { client, loading, data } = useApi<RFQDetails>()
  const { client: clientRound, loading: loadingRound, data: dataRound } = useApi<RFQRoundDetails>()
  const { client: clientRoundQuotes, loading: loadingRoundQuotes, data: dataRoundQuotes } = useApi<{ quotes: QuotesByEngagementType }>()
  const { client: clientRoundSelection, loading: loadingRoundSelection, data: dataRoundSelection } = useApi<QuotesSelectionPreview>()
  const { client: clientAction, loading: loadingAction } = useApi()

  const { project } = useAuth()

  const projectId = computed(() =>
    project?.isResearch ? FORGD_SYSTEM_PROJECT_ID : project?.id,
  )

  const navItems = ref<NavItem[]>([
    { label: 'Overview', link: '/engage-a-market-maker', status: 'none' },
    { label: RFQ_NAV_ITEM, link: '/engage-a-market-maker/request-for-quotations', status: 'available' },
    { label: ROUND_1_NAV_ITEM, link: '/engage-a-market-maker/received-quotes', status: 'lock' },
    { label: ROUND_2_NAV_ITEM, link: '/engage-a-market-maker/received-requotes', status: 'lock' },
  ])

  const mmNavItem = ref<NavItem>({ label: 'Add Market Maker Details', link: '/engage-a-market-maker/market-maker-details', status: 'available' })

  /**
   * selected[Foo]Native = the format used by tanstack table, so we can control the selection
   */
  const selectedLoanCallOptionQuotesNative = ref<Record<string, boolean>>({})
  const selectedLoanCallOptionQuotes = computed(() => Object.entries(selectedLoanCallOptionQuotesNative.value).filter(([_, value]) => value).map(([key]) => key))
  const selectedRetainerWorkingCapitalQuotesNative = ref<Record<string, boolean>>({})
  const selectedRetainerWorkingCapitalQuotes = computed(() => Object.entries(selectedRetainerWorkingCapitalQuotesNative.value).filter(([_, value]) => value).map(([key]) => key))
  const totalSelectedQuotes = computed(() => selectedLoanCallOptionQuotes.value.length + selectedRetainerWorkingCapitalQuotes.value.length)
  const showMaxLimitModal = ref(false)

  /**
   * Set by select button in MmQuoteReviewTable.vue
   */
  const lastSelectedQuoteId = ref<string | null>(null)

  watch(() => totalSelectedQuotes.value, () => {
    if (totalSelectedQuotes.value > MAX_SELECTED_QUOTES) {
      showMaxLimitModal.value = true
      // remove the last selected quote id from the native selection
      if (lastSelectedQuoteId.value) {
        selectedLoanCallOptionQuotesNative.value[lastSelectedQuoteId.value] = false
        selectedRetainerWorkingCapitalQuotesNative.value[lastSelectedQuoteId.value] = false
      }
    }
  })

  async function fetch() {
    await client.marketMakerRFQ.getRFQDetails({
      params: {
        projectId: projectId.value,
      },
    })
  }

  async function fetchRound(round: number) {
    await clientRound.marketMakerRFQ.getRoundDetails({
      params: {
        projectId: projectId.value,
      },
      query: {
        round,
      },
    })
  }

  async function fetchRoundSelection(round: number) {
    await clientRoundSelection.marketMakerRFQ.getQuotesSelectionPreview({
      params: {
        projectId: projectId.value,
      },
      query: {
        round,
        selectedQuoteIds: totalSelectedQuotes.value > 0 ? [...selectedLoanCallOptionQuotes.value, ...selectedRetainerWorkingCapitalQuotes.value] : undefined,
      },
    })
  }

  async function fetchRoundQuotes(round: number) {
    await clientRoundQuotes.marketMakerRFQ.getQuotesBreakdown({
      params: {
        projectId: projectId.value,
      },
      query: {
        round,
      },
    })
  }

  async function accept(round: number) {
    await clientAction.marketMakerRFQ.accept({
      params: {
        projectId: projectId.value,
      },
      body: {
        round,
        quoteIds: [...selectedLoanCallOptionQuotes.value, ...selectedRetainerWorkingCapitalQuotes.value],
      },
    })
  }

  async function requote() {
    await clientAction.marketMakerRFQ.requote({
      params: {
        projectId: projectId.value,
      },
      body: {
        quoteIds: [...selectedLoanCallOptionQuotes.value, ...selectedRetainerWorkingCapitalQuotes.value],
      },
    })
  }

  function resetSelection() {
    selectedLoanCallOptionQuotesNative.value = {}
    selectedRetainerWorkingCapitalQuotesNative.value = {}
  }

  function isFormDirty() {
    return Object.keys(selectedLoanCallOptionQuotesNative.value).length > 0 || Object.keys(selectedRetainerWorkingCapitalQuotesNative.value).length > 0
  }

  const roundDateInfo = computed<RoundInfo[]>(() => {
    const acceptedRound1Status = data?.value?.statuses.find(d => d.status === MarketMakerRequestStatusEnum.RoundOneQuotesAccepted)
    const acceptedRound2Status = data?.value?.statuses.find(d => d.status === MarketMakerRequestStatusEnum.RoundTwoQuotesAccepted)
    const requotedRound1Status = data?.value?.statuses.find(d => d.status === MarketMakerRequestStatusEnum.RoundTwoWaitingForQuotes)
    const info: RoundInfo[] = []

    if (acceptedRound1Status?.date || requotedRound1Status?.date) {
      info.push({
        type: acceptedRound1Status ? 'accepted' : 'requoted',
        dateISO: (acceptedRound1Status?.date || requotedRound1Status?.date)!,
        round: 1,
      })
    }

    if (acceptedRound2Status?.date) {
      info.push({
        type: 'accepted',
        dateISO: acceptedRound2Status.date,
        round: 2,
      })
    }

    if (dataRound?.value?.quotesSummary.quotesReceptionDate) {
      const date = parse(dataRound!.value.quotesSummary.quotesReceptionDate, 'yyyy-MM-dd', new Date())
      const dateToReply = addDays(date, MAX_DAYS_FOR_USER_TO_MAKE_DECISION_ON_QUOTES)
      info.push({
        type: 'new',
        dateISO: dateToReply.toISOString(),
      })
    }

    return info
  })

  watch(() => data, (val) => {
    if (val?.value) {
      if (val.value.project.hasEngagedMarketMakers) {
        mmNavItem.value.status = 'completed'
      }

      const rfq = navItems.value.find(navItem => navItem.label === RFQ_NAV_ITEM)
      const round1 = navItems.value.find(navItem => navItem.label === ROUND_1_NAV_ITEM)
      const round2 = navItems.value.find(navItem => navItem.label === ROUND_2_NAV_ITEM)

      val.value.statuses.forEach((d) => {
        switch (d.status) {
          case 'new':
            rfq!.status = 'completed'
            break
          case 'round_one_quotes_in_review':
          case 'round_one_waiting_for_quotes':
            round1!.status = 'available'
            break
          case 'round_one_quotes_accepted':
            round1!.status = 'completed'
            break
          case 'round_two_quotes_in_review':
          case 'round_two_waiting_for_quotes':
            round1!.status = 'requoted'
            round2!.status = 'available'
            break
          case 'round_two_quotes_accepted':
            round2!.status = 'completed'
            break
        }
      })
    }
  }, { deep: true })

  watch(() => project, (val, oldVal) => {
    if (oldVal && val?.id !== oldVal.id) {
      fetch()
    }
  }, { deep: true })

  return {
    loading: loading || loadingRound || loadingAction,
    loadingRoundQuotes,
    loadingRoundSelection,
    data,
    dataRound,
    dataRoundQuotes,
    dataRoundSelection,
    navItems,
    mmNavItem,
    roundDateInfo,
    selectedLoanCallOptionQuotes,
    selectedLoanCallOptionQuotesNative,
    selectedRetainerWorkingCapitalQuotes,
    selectedRetainerWorkingCapitalQuotesNative,
    totalSelectedQuotes,
    showMaxLimitModal,
    lastSelectedQuoteId,
    isFormDirty,
    fetch,
    fetchRound,
    fetchRoundQuotes,
    fetchRoundSelection,
    accept,
    requote,
    resetSelection,
  }
})
