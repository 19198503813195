import { contract } from '@forgd/contract/contract'
import { initClient, tsRestFetchApi } from '@ts-rest/core'

/**
 * @deprecated Use $core or useCoreData() instead
 */
export function useClient() {
  const nuxtApp = useNuxtApp()
  const runtimeConfig = useRuntimeConfig()
  const baseUrl = import.meta.server ? runtimeConfig.public.apiUrl : '/proxy'

  const cookie = useCookie<string>(runtimeConfig.public.cookie)
  const headers: Record<string, string> = import.meta.server
    ? {
        cookie: `${runtimeConfig.public.cookie}=${cookie.value}`,
      }
    : {}

  return initClient(contract, {
    baseUrl,
    baseHeaders: headers,
    credentials: 'include',
    api: async (args) => {
      const response = await tsRestFetchApi(args)

      if (response.status === 403) {
        await nuxtApp.hooks.callHook('forgd:fetch:unauthorized', { args, res: response })
      }

      return response
    },
  })
}
