import { z } from 'zod'
import {
  EngagementOptionEnumValues,
  MarketMakerVolumeTypeEnum,
  type FdvCategoryEnumType,
  type ExchangeTierEnumType,
  MarketMakerVolumeTypeEnumValues,
  FdvCategoryEnumValues,
  ExchangeTierEnumValues,
} from '@forgd/supabase'
import {
  DepthLevelOptionEnum,
  DepthLevelOptionEnumValues,
  LiquidityTypeOptionEnum,
  LiquidityTypeOptionEnumValues,
  MMLeaderboardFilterByOptionEnum,
  MMLeaderboardFilterByOptionEnumValues,
  RankPresentationOptionEnum,
  RankPresentationOptionEnumValues,
  RankValueOptionEnum,
  RankValueOptionEnumValues,
} from '../../enums'

enum MMRFQParticipationStatuses {
  Selected = 1,
  NotSelected = 2,
  Ignored = 3,
}

export const MarketMakerLeaderboardQueryRequest = z.object({
  filter: z.enum(MMLeaderboardFilterByOptionEnumValues).default(MMLeaderboardFilterByOptionEnum.All),
  fdvCategories: z
    .union([z.string().transform((str) => [str] as FdvCategoryEnumType[]), z.array(z.enum(FdvCategoryEnumValues))])
    .optional(),
  exchangeTiers: z
    .union([z.string().transform((str) => [str] as ExchangeTierEnumType[]), z.array(z.enum(ExchangeTierEnumValues))])
    .optional(),
  volumeType: z.enum(MarketMakerVolumeTypeEnumValues).optional().default(MarketMakerVolumeTypeEnum.Total),
  liquidityType: z.enum(LiquidityTypeOptionEnumValues).optional().default(LiquidityTypeOptionEnum.BidAsk),
  depthLevel: z.enum(DepthLevelOptionEnumValues).optional().default(DepthLevelOptionEnum.Depth200),
  engagementOption: z.enum(EngagementOptionEnumValues).optional(),
  rankValue: z.enum(RankValueOptionEnumValues).optional().default(RankValueOptionEnum.Dollar),
  rankPresentation: z.enum(RankPresentationOptionEnumValues).optional().default(RankPresentationOptionEnum.Values),
})

export type MarketMakerLeaderboardQuery = z.infer<typeof MarketMakerLeaderboardQueryRequest>

export const MMLeaderBoardItem = z.object({
  rank: z.number(),
  marketMakerId: z.string(),
  marketMakerName: z.string(),
  imageUrl: z.string(),
  projectsTracked: z.number(),
  avgProjectFDV: z.number(),
  tier1: z.number(),
  tier2: z.number(),
  tier3: z.number(),
  engagementOptions: z.array(z.enum(EngagementOptionEnumValues)).min(1).nullable(),
})

const MMLeaderBoardItemByDepth = MMLeaderBoardItem.extend({
  avgDollarDepth: z.number().nullable(),
  avgDepthOfTotal: z.number().nullable(),
})

const MMLeaderBoardItemBySpread = MMLeaderBoardItem.extend({
  avgSpreadPercent: z.number().nullable(),
})

const MMLeaderBoardItemByVolume = MMLeaderBoardItem.extend({
  avgDailyVolume: z.number().nullable(),
  avgVolumeOfTotal: z.number().nullable(),
})

const MMLeaderBoardItemAll = MMLeaderBoardItem.extend({
  depthRank: z.number(),
  volumeRank: z.number(),
  spreadRank: z.number(),
  depthValue: z.number().nullable(),
  volumeValue: z.number().nullable(),
  spreadValue: z.number().nullable(),
})

export const MarketMakerLeaderboardResponse = z.object({
  all: z.array(MMLeaderBoardItemAll),
  byDepth: z.array(MMLeaderBoardItemByDepth),
  byVolume: z.array(MMLeaderBoardItemByVolume),
  bySpread: z.array(MMLeaderBoardItemBySpread),
})

export type MarketMakerLeaderboard = z.infer<typeof MarketMakerLeaderboardResponse>

export { MMRFQParticipationStatuses }
