<script setup lang="ts">
import { sizes } from '../text/UiFormat.vue'

/**
 * UiTrend
 *
 * Renders a value as a number with icon and color
 */
const props = defineProps<{
  value: number | string
  format?: ValueFormat
  size?: keyof typeof sizes
  iconOnly?: boolean
}>()

const trendClasses = {
  '1': 'text-forgd-green-600',
  '0': 'text-gray-700',
  '-1': 'text-red-600',
}

const trend = computed<-1 | 0 | 1>(() => {
  const value = typeof props.value === 'string'
    ? Number.parseFloat(props.value)
    : props.value
  return value > 0
    ? 1
    : value < 0
      ? -1
      : 0
})

const classes = computed(() => {
  const key = trend.value
  return [
    trendClasses[key],
    sizes[props.size as keyof typeof sizes],
  ]
})

const icon = computed(() => {
  const rotate = {
    '1': '',
    '0': 'rotate-45',
    '-1': 'rotate-90',
  }
  return rotate[trend.value]
})
</script>

<template>
  <span data-ui="UiTrend" class="flex items-center font-display" :class="classes">
    <UiFormat class="flex gap-1 items-center whitespace-nowrap leading-none" :format :value="iconOnly ? '' : value" :size="size">
      <template #suffix>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10" height="10" viewBox="0 0 10 10"
          :style="`height: .9em; width: .9em; margin-left: ${iconOnly ? '' : '.1em'}`"
          :class="icon"
        >
          <path
            d="M1.25 8.75L8.75 1.25M8.75 1.25L3.125 1.25M8.75 1.25V6.875"
            stroke="currentColor"
            :stroke-width="size === '2xs' ? 2 : 1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </template>
    </UiFormat>
  </span>
</template>
