import { twMerge } from 'tailwind-merge'

export const HEIGHTS = {
  // heights defined by Lucas
  xs: 'h-[24px]', // h-6
  sm: 'h-[32px]', // h-8
  md: 'h-[40px]', // h-10
  lg: 'h-[52px]', // h-13 (doesn't exist)
  xl: 'h-[60px]', // h-15 (doesn't exist)
}

export const SIZES = Object.keys(HEIGHTS)

export type ControlSize = keyof typeof HEIGHTS

interface Props {
  type?: string
  size: ControlSize
  disabled?: boolean
  status?: 'success' | 'warning' | 'error'
}

/**
 * Generate common classes across all inputs
 */
export function useInputClasses(props: Props, model: Ref) {
  const hasValue = computed(() => {
    return model.value !== '' && model.value !== null && model.value !== undefined
  })

  const isDirty = computed(() => model.value !== props.modelValue)

  const isEmpty = computed(() => {
    return model.value === undefined || (typeof model.value === 'string' && model.value.trim() === '')
  })

  const color = computed(() => {
    return props.disabled
      ? 'text-neutral-900/30'
      : hasValue.value
        ? 'text-brand-900'
        : 'text-neutral-900/60'
  })

  const text = computed(() => {
    const sizes = {
      xs: 'text-xs',
      sm: 'text-xs',
      md: 'text-base',
      lg: 'text-base',
      xl: 'text-lg',
    }
    return sizes[props.size]
  })

  const size = computed(() => {
    const height = HEIGHTS[props.size]
    const padding = {
      xs: 'px-2',
      sm: 'px-2',
      md: 'px-2',
      lg: 'px-3',
      xl: 'px-4',
    }
    return `${padding[props.size]} ${height}`
  })

  const status = computed(() => {
    const status = {
      success: 'ring-green-600',
      error: 'ring-red-600',
    }
    return props.status && status[props.status]
  })

  const disabled = computed(() => {
    return props.disabled
      ? 'bg-neutral-600/30 ring-neutral-600/60 pointer-events-none'
      : ''
  })

  const interaction = computed(() => {
    const hover = !props.disabled
      ? '[&:not(:focus-within):hover]:bg-neutral-600/10' // [&:not(:focus-within):hover_input]:text-brand-100
      : ''
    const focus = !props.status
      ? 'focus-within:ring-brand-100'
      : ''
    return `${hover} ${focus}`
  })

  const element = computed(() => {
    return twMerge([
      `w-auto rounded-lg [&_*]:leading-normal
      text-forgd-primary-900 font-display
      border-0 ring-1 ring-inset
      ring-forgd-bgd-600
      focus-within:ring-inset focus-within:ring-2`,
      disabled.value,
      status.value,
      interaction.value,
      color.value,
      text.value,
      size.value,
    ])
  })

  const input = computed(() => {
    return twMerge([
      text.value,
      color.value,
      props.type === 'password' && '[&::-ms-reveal]:hidden [&::-ms-clear]:hidden [appearance:none] [&::-webkit-credentials-auto-fill-button]:hidden',
      `grow w-full min-w-0
      border-0 ring-0 focus:border-0 focus:ring-0
      !p-0 !bg-transparent`,
    ])
  })

  const slot = computed(() => {
    return twMerge([
      text.value,
      color.value,
    ])
  })

  return reactive({
    // states
    hasValue,
    isEmpty,
    isDirty,

    // helper classes
    color,
    text,
    size,
    status,
    disabled,
    interaction,

    // component classes
    element,
    input,
    slot,
  })
}
