export const Amm2SStatusEnum = {
  InReview: "in-review",
  Active: "active",
  Delayed: "delayed",
  Scheduled: "scheduled",
  Deactivated: "deactivated",
  Draft: "draft",
  Rejected: "rejected",
} as const;

export type Amm2SStatusEnumType = (typeof Amm2SStatusEnum)[keyof typeof Amm2SStatusEnum]

export const Amm2SStatusEnumValues = [
  "in-review",
  "active",
  "delayed",
  "scheduled",
  "deactivated",
  "draft",
  "rejected",
] as const;

export const Amm2SStrategyEnum = {
  DeltaNeutral: "delta-neutral",
  Tunnel: "tunnel",
  Migrate: "migrate",
  Arbitrage: "arbitrage",
} as const;

export type Amm2SStrategyEnumType = (typeof Amm2SStrategyEnum)[keyof typeof Amm2SStrategyEnum]

export const Amm2SStrategyEnumValues = [
  "delta-neutral",
  "tunnel",
  "migrate",
  "arbitrage",
] as const;

export const Amm2STradingstyleEnum = {
  Conservative: "conservative",
  Moderate: "moderate",
  Aggressive: "aggressive",
} as const;

export type Amm2STradingstyleEnumType = (typeof Amm2STradingstyleEnum)[keyof typeof Amm2STradingstyleEnum]

export const Amm2STradingstyleEnumValues = [
  "conservative",
  "moderate",
  "aggressive",
] as const;

export const AreasOfInterestEnum = {
  TokenomicsAndProtocol: "tokenomics_and_protocol",
  GoToMarketPartnerships: "go_to_market_partnerships",
  LegalCompliance: "legal_compliance",
  FinanceAndFundraising: "finance_and_fundraising",
  LaunchChecklist: "launch_checklist",
  TokenomicsResearch: "tokenomics_research",
  MarketMakingResearch: "market_making_research",
  ExchangeListingResearch: "exchange_listing_research",
  AnalysisAndInsights: "analysis_and_insights",
  StrategicPartnerships: "strategic_partnerships",
  PerformanceOptimization: "performance_optimization",
} as const;

export type AreasOfInterestEnumType = (typeof AreasOfInterestEnum)[keyof typeof AreasOfInterestEnum]

export const AreasOfInterestEnumValues = [
  "tokenomics_and_protocol",
  "go_to_market_partnerships",
  "legal_compliance",
  "finance_and_fundraising",
  "launch_checklist",
  "tokenomics_research",
  "market_making_research",
  "exchange_listing_research",
  "analysis_and_insights",
  "strategic_partnerships",
  "performance_optimization",
] as const;

export const BaseLayerTypeEnum = {
  Bitcoin: "Bitcoin",
  Ethereum: "Ethereum",
  Solana: "Solana",
  Aptos: "Aptos",
  Arbitrum: "Arbitrum",
  Avalanche: "Avalanche",
  Injective: "Injective",
  Optimism: "Optimism",
  Polkadot: "Polkadot",
  RadixEcosystem: "Radix Ecosystem",
  Sei: "Sei",
  Sui: "Sui",
  Tron: "TRON",
  Other: "Other",
} as const;

export type BaseLayerTypeEnumType = (typeof BaseLayerTypeEnum)[keyof typeof BaseLayerTypeEnum]

export const BaseLayerTypeEnumValues = [
  "Bitcoin",
  "Ethereum",
  "Solana",
  "Aptos",
  "Arbitrum",
  "Avalanche",
  "Injective",
  "Optimism",
  "Polkadot",
  "Radix Ecosystem",
  "Sei",
  "Sui",
  "TRON",
  "Other",
] as const;

export const ContactTypeEnum = {
  Email: "email",
  Telegram: "telegram",
} as const;

export type ContactTypeEnumType = (typeof ContactTypeEnum)[keyof typeof ContactTypeEnum]

export const ContactTypeEnumValues = [
  "email",
  "telegram",
] as const;

export const ExchangeAccountTypeEnum = {
  Primary: "primary",
  Secondary: "secondary",
} as const;

export type ExchangeAccountTypeEnumType = (typeof ExchangeAccountTypeEnum)[keyof typeof ExchangeAccountTypeEnum]

export const ExchangeAccountTypeEnumValues = [
  "primary",
  "secondary",
] as const;

export const ExchangeMcapTierEnum = {
  Major: "major",
  Large: "large",
  Mid: "mid",
  Low: "low",
  Micro: "micro",
} as const;

export type ExchangeMcapTierEnumType = (typeof ExchangeMcapTierEnum)[keyof typeof ExchangeMcapTierEnum]

export const ExchangeMcapTierEnumValues = [
  "major",
  "large",
  "mid",
  "low",
  "micro",
] as const;

export const ExchangeTierEnum = {
  Tier1: "tier_1",
  Tier2: "tier_2",
  Tier3: "tier_3",
} as const;

export type ExchangeTierEnumType = (typeof ExchangeTierEnum)[keyof typeof ExchangeTierEnum]

export const ExchangeTierEnumValues = [
  "tier_1",
  "tier_2",
  "tier_3",
] as const;

export const ExchangeTypeEnum = {
  Cex: "CEX",
  Dex: "DEX",
} as const;

export type ExchangeTypeEnumType = (typeof ExchangeTypeEnum)[keyof typeof ExchangeTypeEnum]

export const ExchangeTypeEnumValues = [
  "CEX",
  "DEX",
] as const;

export const FdvCategoryEnum = {
  Major: "major",
  Large: "large",
  Mid: "mid",
  Low: "low",
  Micro: "micro",
} as const;

export type FdvCategoryEnumType = (typeof FdvCategoryEnum)[keyof typeof FdvCategoryEnum]

export const FdvCategoryEnumValues = [
  "major",
  "large",
  "mid",
  "low",
  "micro",
] as const;

export const LeadSourceTypeEnum = {
  Twitter: "twitter",
  Linkedin: "linkedin",
  Email: "email",
  Referral: "referral",
  TeammateInvite: "teammate_invite",
  Other: "other",
} as const;

export type LeadSourceTypeEnumType = (typeof LeadSourceTypeEnum)[keyof typeof LeadSourceTypeEnum]

export const LeadSourceTypeEnumValues = [
  "twitter",
  "linkedin",
  "email",
  "referral",
  "teammate_invite",
  "other",
] as const;

export const MemberStatusEnum = {
  Active: "active",
  Deleted: "deleted",
  Invited: "invited",
} as const;

export type MemberStatusEnumType = (typeof MemberStatusEnum)[keyof typeof MemberStatusEnum]

export const MemberStatusEnumValues = [
  "active",
  "deleted",
  "invited",
] as const;

export const SectorTypeEnum = {
  DeFi: "DeFi",
  GameFi: "GameFi",
  Social: "Social",
  DePin: "DePIN",
  AiAndData: "AI & Data",
  BaseLayer: "Base Layer",
  Memecoin: "Memecoin",
  Wallet: "Wallet",
  Gambling: "Gambling",
  Oracle: "Oracle",
  ECommerce: "eCommerce",
} as const;

export type SectorTypeEnumType = (typeof SectorTypeEnum)[keyof typeof SectorTypeEnum]

export const SectorTypeEnumValues = [
  "DeFi",
  "GameFi",
  "Social",
  "DePIN",
  "AI & Data",
  "Base Layer",
  "Memecoin",
  "Wallet",
  "Gambling",
  "Oracle",
  "eCommerce",
] as const;

export const TypeOfRaiseEnum = {
  TokenSale: "token-sale",
  Equity: "equity",
  Debt: "debt",
  Combination: "combination",
  Other: "other",
  Uncertain: "uncertain",
} as const;

export type TypeOfRaiseEnumType = (typeof TypeOfRaiseEnum)[keyof typeof TypeOfRaiseEnum]

export const TypeOfRaiseEnumValues = [
  "token-sale",
  "equity",
  "debt",
  "combination",
  "other",
  "uncertain",
] as const;

export const ActivityExecutionTypeEnum = {
  Continuous: "continuous",
  AdHoc: "ad_hoc",
} as const;

export type ActivityExecutionTypeEnumType = (typeof ActivityExecutionTypeEnum)[keyof typeof ActivityExecutionTypeEnum]

export const ActivityExecutionTypeEnumValues = [
  "continuous",
  "ad_hoc",
] as const;

export const ActivityReviewIntervalEnum = {
  Daily: "daily",
  Monthly: "monthly",
  Quarterly: "quarterly",
  Weekly: "weekly",
} as const;

export type ActivityReviewIntervalEnumType = (typeof ActivityReviewIntervalEnum)[keyof typeof ActivityReviewIntervalEnum]

export const ActivityReviewIntervalEnumValues = [
  "daily",
  "monthly",
  "quarterly",
  "weekly",
] as const;

export const EngagementOptionEnum = {
  LoanCallOption: "loan_call_option",
  RetainerWorkingCapital: "retainer_working_capital",
} as const;

export type EngagementOptionEnumType = (typeof EngagementOptionEnum)[keyof typeof EngagementOptionEnum]

export const EngagementOptionEnumValues = [
  "loan_call_option",
  "retainer_working_capital",
] as const;

export const ExchangeListingFeeDenominatorEnum = {
  Tokens: "tokens",
  Stablecoins: "stablecoins",
} as const;

export type ExchangeListingFeeDenominatorEnumType = (typeof ExchangeListingFeeDenominatorEnum)[keyof typeof ExchangeListingFeeDenominatorEnum]

export const ExchangeListingFeeDenominatorEnumValues = [
  "tokens",
  "stablecoins",
] as const;

export const ExchangeListingFeeMethodologyEnum = {
  Percentage: "percentage",
  Usd: "usd",
} as const;

export type ExchangeListingFeeMethodologyEnumType = (typeof ExchangeListingFeeMethodologyEnum)[keyof typeof ExchangeListingFeeMethodologyEnum]

export const ExchangeListingFeeMethodologyEnumValues = [
  "percentage",
  "usd",
] as const;

export const ExchangeListingFeeTypeEnum = {
  ListingAndIntegrationFee: "listing_and_integration_fee",
  MarketingBudget: "marketing_budget",
  SecurityDeposit: "security_deposit",
  MarketMakerDeposit: "market_maker_deposit",
} as const;

export type ExchangeListingFeeTypeEnumType = (typeof ExchangeListingFeeTypeEnum)[keyof typeof ExchangeListingFeeTypeEnum]

export const ExchangeListingFeeTypeEnumValues = [
  "listing_and_integration_fee",
  "marketing_budget",
  "security_deposit",
  "market_maker_deposit",
] as const;

export const ExchangeQuestionnaireCategoriesEnum = {
  BusinessModel: "business_model",
  InvestorsAndPartners: "investors_and_partners",
  General: "general",
  Tokenomics: "tokenomics",
  ProductAndRoadmap: "product_and_roadmap",
  Kpis: "kpis",
  Team: "team",
  Competition: "competition",
  AdministrativeCompliance: "administrative_compliance",
  ProductRoadmap: "product_roadmap",
  Finance: "finance",
  Community: "community",
} as const;

export type ExchangeQuestionnaireCategoriesEnumType = (typeof ExchangeQuestionnaireCategoriesEnum)[keyof typeof ExchangeQuestionnaireCategoriesEnum]

export const ExchangeQuestionnaireCategoriesEnumValues = [
  "business_model",
  "investors_and_partners",
  "general",
  "tokenomics",
  "product_and_roadmap",
  "kpis",
  "team",
  "competition",
  "administrative_compliance",
  "product_roadmap",
  "finance",
  "community",
] as const;

export const ExchangeQuestionnaireDocDescEnum = {
  FirstRoundDueDiligence: "first_round_due_diligence",
  SecondRoundDueDiligence: "second_round_due_diligence",
  RegulatoryChecklist: "regulatory_checklist",
  PreListingChecklist: "pre_listing_checklist",
  SupplementaryDueDiligence: "supplementary_due_diligence",
} as const;

export type ExchangeQuestionnaireDocDescEnumType = (typeof ExchangeQuestionnaireDocDescEnum)[keyof typeof ExchangeQuestionnaireDocDescEnum]

export const ExchangeQuestionnaireDocDescEnumValues = [
  "first_round_due_diligence",
  "second_round_due_diligence",
  "regulatory_checklist",
  "pre_listing_checklist",
  "supplementary_due_diligence",
] as const;

export const ExchangeQuestionnaireTypeEnum = {
  TextInput: "text_input",
  MultipleChoice: "multiple_choice",
  UploadFile: "upload_file",
  Link: "link",
  CompleteTable: "complete_table",
  CheckBox: "check_box",
  DatePicker: "date_picker",
} as const;

export type ExchangeQuestionnaireTypeEnumType = (typeof ExchangeQuestionnaireTypeEnum)[keyof typeof ExchangeQuestionnaireTypeEnum]

export const ExchangeQuestionnaireTypeEnumValues = [
  "text_input",
  "multiple_choice",
  "upload_file",
  "link",
  "complete_table",
  "check_box",
  "date_picker",
] as const;

export const MarketMakerEngagementsStatusEnum = {
  New: "new",
  Active: "active",
  Inactive: "inactive",
} as const;

export type MarketMakerEngagementsStatusEnumType = (typeof MarketMakerEngagementsStatusEnum)[keyof typeof MarketMakerEngagementsStatusEnum]

export const MarketMakerEngagementsStatusEnumValues = [
  "new",
  "active",
  "inactive",
] as const;

export const MarketMakerQuoteKpiMetricEnum = {
  LoanValueDepth50Bps: "loan_value_depth_50_bps",
  LoanValueDepth100Bps: "loan_value_depth_100_bps",
  LoanValueDepth200Bps: "loan_value_depth_200_bps",
  BidAskSpreadBps: "bid_ask_spread_bps",
} as const;

export type MarketMakerQuoteKpiMetricEnumType = (typeof MarketMakerQuoteKpiMetricEnum)[keyof typeof MarketMakerQuoteKpiMetricEnum]

export const MarketMakerQuoteKpiMetricEnumValues = [
  "loan_value_depth_50_bps",
  "loan_value_depth_100_bps",
  "loan_value_depth_200_bps",
  "bid_ask_spread_bps",
] as const;

export const MarketMakerQuoteKpiMinMaxUnitEnum = {
  Percent: "percent",
  Usd: "usd",
} as const;

export type MarketMakerQuoteKpiMinMaxUnitEnumType = (typeof MarketMakerQuoteKpiMinMaxUnitEnum)[keyof typeof MarketMakerQuoteKpiMinMaxUnitEnum]

export const MarketMakerQuoteKpiMinMaxUnitEnumValues = [
  "percent",
  "usd",
] as const;

export const MarketMakerQuoteLoanCustodyEnum = {
  MarketMaker: "market_maker",
  Project: "project",
} as const;

export type MarketMakerQuoteLoanCustodyEnumType = (typeof MarketMakerQuoteLoanCustodyEnum)[keyof typeof MarketMakerQuoteLoanCustodyEnum]

export const MarketMakerQuoteLoanCustodyEnumValues = [
  "market_maker",
  "project",
] as const;

export const MarketMakerQuoteStatusEnum = {
  Draft: "draft",
  Submitted: "submitted",
  Cancelled: "cancelled",
  Voided: "voided",
  Accepted: "accepted",
} as const;

export type MarketMakerQuoteStatusEnumType = (typeof MarketMakerQuoteStatusEnum)[keyof typeof MarketMakerQuoteStatusEnum]

export const MarketMakerQuoteStatusEnumValues = [
  "draft",
  "submitted",
  "cancelled",
  "voided",
  "accepted",
] as const;

export const MarketMakerQuoteTranchePriceMethodologyEnum = {
  Fixed: "fixed",
  Dynamic: "dynamic",
} as const;

export type MarketMakerQuoteTranchePriceMethodologyEnumType = (typeof MarketMakerQuoteTranchePriceMethodologyEnum)[keyof typeof MarketMakerQuoteTranchePriceMethodologyEnum]

export const MarketMakerQuoteTranchePriceMethodologyEnumValues = [
  "fixed",
  "dynamic",
] as const;

export const MarketMakerRequestStatusEnum = {
  New: "new",
  RoundOneWaitingForQuotes: "round_one_waiting_for_quotes",
  RoundOneQuotesInReview: "round_one_quotes_in_review",
  RoundOneQuotesAccepted: "round_one_quotes_accepted",
  RoundTwoWaitingForQuotes: "round_two_waiting_for_quotes",
  RoundTwoQuotesInReview: "round_two_quotes_in_review",
  RoundTwoQuotesAccepted: "round_two_quotes_accepted",
} as const;

export type MarketMakerRequestStatusEnumType = (typeof MarketMakerRequestStatusEnum)[keyof typeof MarketMakerRequestStatusEnum]

export const MarketMakerRequestStatusEnumValues = [
  "new",
  "round_one_waiting_for_quotes",
  "round_one_quotes_in_review",
  "round_one_quotes_accepted",
  "round_two_waiting_for_quotes",
  "round_two_quotes_in_review",
  "round_two_quotes_accepted",
] as const;

export const MarketMakerVolumeTypeEnum = {
  Maker: "maker",
  Taker: "taker",
  Total: "total",
} as const;

export type MarketMakerVolumeTypeEnumType = (typeof MarketMakerVolumeTypeEnum)[keyof typeof MarketMakerVolumeTypeEnum]

export const MarketMakerVolumeTypeEnumValues = [
  "maker",
  "taker",
  "total",
] as const;

export const OptionsStyleEnum = {
  American: "american",
  European: "european",
} as const;

export type OptionsStyleEnumType = (typeof OptionsStyleEnum)[keyof typeof OptionsStyleEnum]

export const OptionsStyleEnumValues = [
  "american",
  "european",
] as const;

export const OrganizationMemberRoleEnum = {
  Owner: "owner",
  Viewer: "viewer",
} as const;

export type OrganizationMemberRoleEnumType = (typeof OrganizationMemberRoleEnum)[keyof typeof OrganizationMemberRoleEnum]

export const OrganizationMemberRoleEnumValues = [
  "owner",
  "viewer",
] as const;

export const ProjectActivityStatusEnum = {
  NotStarted: "not_started",
  InProgress: "in_progress",
  OnHold: "on_hold",
  Stuck: "stuck",
  Completed: "completed",
} as const;

export type ProjectActivityStatusEnumType = (typeof ProjectActivityStatusEnum)[keyof typeof ProjectActivityStatusEnum]

export const ProjectActivityStatusEnumValues = [
  "not_started",
  "in_progress",
  "on_hold",
  "stuck",
  "completed",
] as const;

export const ProjectStatusEnum = {
  Onboarding: "onboarding",
  Active: "active",
  Deleted: "deleted",
} as const;

export type ProjectStatusEnumType = (typeof ProjectStatusEnum)[keyof typeof ProjectStatusEnum]

export const ProjectStatusEnumValues = [
  "onboarding",
  "active",
  "deleted",
] as const;

export const ProjectTgeStageEnum = {
  PreTge: "pre_tge",
  PostTge: "post_tge",
} as const;

export type ProjectTgeStageEnumType = (typeof ProjectTgeStageEnum)[keyof typeof ProjectTgeStageEnum]

export const ProjectTgeStageEnumValues = [
  "pre_tge",
  "post_tge",
] as const;

export const ToolTypeEnum = {
  Internal: "internal",
  External: "external",
  ThirdParty: "thirdParty",
} as const;

export type ToolTypeEnumType = (typeof ToolTypeEnum)[keyof typeof ToolTypeEnum]

export const ToolTypeEnumValues = [
  "internal",
  "external",
  "thirdParty",
] as const;

export const BasicCalculationDemandDenominationEnum = {
  Usd: "usd",
  Tokens: "tokens",
} as const;

export type BasicCalculationDemandDenominationEnumType = (typeof BasicCalculationDemandDenominationEnum)[keyof typeof BasicCalculationDemandDenominationEnum]

export const BasicCalculationDemandDenominationEnumValues = [
  "usd",
  "tokens",
] as const;

export const ChanceOfVoteEnum = {
  Low: "low",
  Medium: "medium",
  High: "high",
} as const;

export type ChanceOfVoteEnumType = (typeof ChanceOfVoteEnum)[keyof typeof ChanceOfVoteEnum]

export const ChanceOfVoteEnumValues = [
  "low",
  "medium",
  "high",
] as const;

export const DemandDriverCalculationTemplateEnum = {
  Basic: "basic",
  TokenBuyBacks: "token-buy-backs",
  Governance: "governance",
  Staking: "staking",
  Locking: "locking",
} as const;

export type DemandDriverCalculationTemplateEnumType = (typeof DemandDriverCalculationTemplateEnum)[keyof typeof DemandDriverCalculationTemplateEnum]

export const DemandDriverCalculationTemplateEnumValues = [
  "basic",
  "token-buy-backs",
  "governance",
  "staking",
  "locking",
] as const;

export const DemandDriverCalculationTypeEnum = {
  Basic: "basic",
  Advanced: "advanced",
  Custom: "custom",
} as const;

export type DemandDriverCalculationTypeEnumType = (typeof DemandDriverCalculationTypeEnum)[keyof typeof DemandDriverCalculationTypeEnum]

export const DemandDriverCalculationTypeEnumValues = [
  "basic",
  "advanced",
  "custom",
] as const;

export const DemandDriverStatusEnum = {
  Active: "active",
  Inactive: "inactive",
} as const;

export type DemandDriverStatusEnumType = (typeof DemandDriverStatusEnum)[keyof typeof DemandDriverStatusEnum]

export const DemandDriverStatusEnumValues = [
  "active",
  "inactive",
] as const;

export const DemandDriverTypeEnum = {
  Utility: "utility",
  Mechanism: "mechanism",
} as const;

export type DemandDriverTypeEnumType = (typeof DemandDriverTypeEnum)[keyof typeof DemandDriverTypeEnum]

export const DemandDriverTypeEnumValues = [
  "utility",
  "mechanism",
] as const;

export const DropOffEnum = {
  Low: "low",
  Medium: "medium",
  High: "high",
} as const;

export type DropOffEnumType = (typeof DropOffEnum)[keyof typeof DropOffEnum]

export const DropOffEnumValues = [
  "low",
  "medium",
  "high",
] as const;

export const GroupCategoryEnum = {
  CoreContributors: "Core Contributors",
  PrivateSale: "Private Sale",
  PublicSale: "Public Sale",
  NetworkIncentives: "Network Incentives",
  EcosystemIncentives: "Ecosystem Incentives",
  LiquidityProvision: "Liquidity Provision",
  Airdrops: "Airdrops",
  Marketing: "Marketing",
  Treasury: "Treasury",
  Miscellaneous: "Miscellaneous",
} as const;

export type GroupCategoryEnumType = (typeof GroupCategoryEnum)[keyof typeof GroupCategoryEnum]

export const GroupCategoryEnumValues = [
  "Core Contributors",
  "Private Sale",
  "Public Sale",
  "Network Incentives",
  "Ecosystem Incentives",
  "Liquidity Provision",
  "Airdrops",
  "Marketing",
  "Treasury",
  "Miscellaneous",
] as const;

export const GroupTypeEnum = {
  Internal: "internal",
  External: "external",
} as const;

export type GroupTypeEnumType = (typeof GroupTypeEnum)[keyof typeof GroupTypeEnum]

export const GroupTypeEnumValues = [
  "internal",
  "external",
] as const;

export const GroupUnlockFrequencyEnum = {
  Daily: "daily",
  Monthly: "monthly",
} as const;

export type GroupUnlockFrequencyEnumType = (typeof GroupUnlockFrequencyEnum)[keyof typeof GroupUnlockFrequencyEnum]

export const GroupUnlockFrequencyEnumValues = [
  "daily",
  "monthly",
] as const;

export const GroupUnlockTypeEnum = {
  EpochBased: "epoch-based",
  LockAndVest: "lock-and-vest",
  FullInitialUnlock: "full-initial-unlock",
} as const;

export type GroupUnlockTypeEnumType = (typeof GroupUnlockTypeEnum)[keyof typeof GroupUnlockTypeEnum]

export const GroupUnlockTypeEnumValues = [
  "epoch-based",
  "lock-and-vest",
  "full-initial-unlock",
] as const;

export const RevenueCalculationTypeEnum = {
  BottomUp: "bottom-up",
  TopDown: "top-down",
  Comparable: "comparable",
} as const;

export type RevenueCalculationTypeEnumType = (typeof RevenueCalculationTypeEnum)[keyof typeof RevenueCalculationTypeEnum]

export const RevenueCalculationTypeEnumValues = [
  "bottom-up",
  "top-down",
  "comparable",
] as const;
