import { type ConsolaOptions, createConsola } from 'consola'
import defu from 'defu'

/**
 * We set the log level to verbose in dev and silent in production by default
 */
const defaultOptions: Partial<ConsolaOptions> = {
  level: import.meta.dev ? 999 : -999,
}

/**
 * Creates a consola instance with a tag and optional options
 * @param tag - The tag to use for the logger
 * @param options - Optional options to pass to the logger
 * @returns A consola instance
 */
export function useDevLogger(tag: string, options?: Partial<ConsolaOptions>) {
  const logger = createConsola(defu(defaultOptions, options, { defaults: { tag } }))

  return logger
}
