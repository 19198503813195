import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { GetActivitiesLeadResult, PlaybookActivityResponse, PostActivitiesLead, ProjectPathParam } from '../types'
import {
  ActivityExecutionTypeEnumValues,
  ActivityReviewIntervalEnumValues,
  ProjectActivityStatusEnumValues,
  ProjectTgeStageEnumValues,
  ToolTypeEnumValues,
} from '@forgd/supabase'

const c = initContract()

export const activitiesContract = c.router({
  getActivities: {
    method: 'GET',
    path: '/activities/project/:projectId',
    pathParams: z.object({
      projectId: z.string().uuid(),
    }),
    responses: {
      200: z.array(
        z.object({
          dependsOnActivityId: z.string().nullable(),
          depth: z.number(),
          description: z.string().nullable(),
          durationEstimateDays: z.number().nullable(),
          executionType: z.enum(ActivityExecutionTypeEnumValues).nullable(),
          id: z.string(),
          order: z.number(),
          parentId: z.string().nullable(),
          resourceUrl: z.string().nullable(),
          reviewInterval: z.enum(ActivityReviewIntervalEnumValues).nullable(),
          stage: z.enum(ProjectTgeStageEnumValues),
          title: z.string(),
          toolType: z.enum(ToolTypeEnumValues).nullable(),
          toolUrl: z.string().nullable(),
          activityState: z.array(
            z.object({
              status: z.enum(ProjectActivityStatusEnumValues),
            }),
          ),
        }),
      ),
    },
    summary: 'Get activities',
  },
  getActivitiesV2: {
    method: 'GET',
    path: '/activities/project/:projectId/v2',
    pathParams: ProjectPathParam,
    responses: {
      200: PlaybookActivityResponse,
    },
    summary: 'Get activities v2',
  },
  setStatus: {
    method: 'POST',
    path: '/activities/status',
    responses: {
      204: z.undefined(),
    },
    body: z.object({
      projectId: z.string().uuid(),
      activityId: z.string(),
      status: z.enum(ProjectActivityStatusEnumValues),
    }),
    summary: 'Set the status of an activity',
  },
  postActivitiesLead: {
    method: 'POST',
    path: '/activities/lead',
    responses: {
      204: z.undefined().optional(),
    },
    body: PostActivitiesLead,
    summary: 'Create activities lead',
  },
  getActivitiesLead: {
    method: 'GET',
    path: '/activities/lead',
    query: z.object({
      projectId: z.string(),
      activityId: z.string().optional(),
    }),
    responses: {
      200: z.array(GetActivitiesLeadResult),
      204: z.undefined().optional(),
    },
    summary: 'Get activities lead',
  },
})

export type ActivitiesContractType = typeof activitiesContract
