import routerOptions0 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.38.4_@opentel_sysu2verttu62bk247oexq2hca/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.29._dkvhintcad2ql7552bvexqg36y/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}