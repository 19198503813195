import { EngagementOptionEnumValues } from '@forgd/supabase'
import { z } from 'zod'

export const MarketMakerSchema = z.object({
  id: z.string(),
  name: z.string(),
  isDemo: z.boolean(),
  includeOnLeaderboard: z.boolean(),
  description: z.string().nullable(),
  imageUrl: z.string().nullable(),
  importName: z.string().nullable(),
  services: z.array(z.string()).nullable(),
  engagementOptions: z.array(z.enum(EngagementOptionEnumValues)).nullable(),
})

export const GetMarketMakersResult = z.array(MarketMakerSchema)
export type GetMarketMakersResultType = z.infer<typeof GetMarketMakersResult>
