import { z } from 'zod'
import { stringToIntNumber } from '@forgd/shared/utils/zod'

export const GetVolumeBreakdownsByExchangeQuery = z.object({
  date: z.string().date(),
  marketMakerId: z.string().nullish(),
  orderBook: z.string().nullish(),
  daysLookback: z
    .string()
    .optional()
    .nullable()
    .transform(stringToIntNumber)
    .refine((val) => val == null || val > 0, {
      message: 'daysLookback must be a positive integer',
    }),
})

export const GetVolumeBreakdownsQuery = GetVolumeBreakdownsByExchangeQuery.extend({
  exchange: z.string().nullish(),
})

export type GetVolumeBreakdownsQueryType = z.infer<typeof GetVolumeBreakdownsQuery>
export type GetVolumeBreakdownsByExchangeQueryType = z.infer<typeof GetVolumeBreakdownsByExchangeQuery>

const GetVolumeBreakdownsByExchangeEntry = z.object({
  exchange: z.string(),
  maker: z.number(),
  makerPercentage: z.number(),
  makerTaker: z.number(),
  makerTakerPercentage: z.number(),
  taker: z.number(),
  takerPercentage: z.number(),
})

const GetVolumeBreakdownsEntry = GetVolumeBreakdownsByExchangeEntry.omit({
  exchange: true,
}).extend({
  allOthersMaker: z.number(),
  allOthersMakerPercentage: z.number(),
  allOthersMakerTaker: z.number(),
  allOthersMakerTakerPercentage: z.number(),
  allOthersTaker: z.number(),
  allOthersTakerPercentage: z.number(),
  overreportingMaker: z.number(),
  overreportingTaker: z.number(),
  overreportingMakerTaker: z.number(),
  warnings: z
    .object({
      maker: z.boolean(),
      taker: z.boolean(),
      makerTaker: z.boolean(),
    })
    .nullable(),
})

export const GetVolumeBreakdownsByExchangeResult = z.array(GetVolumeBreakdownsByExchangeEntry)
export const GetVolumeBreakdownsResult = z.array(GetVolumeBreakdownsEntry)
