import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { GetMarketMakersResult } from '../types'

const c = initContract()

export const marketMakerContract = c.router({
  getMarketMakers: {
    method: 'GET',
    path: `/market-makers`,
    query: z.object({
      isDemo: z.string().transform((val) => val === 'true'),
    }),
    responses: {
      200: GetMarketMakersResult,
    },
    summary: 'Get market makers',
  },
})

export type MarketMakerContractType = typeof marketMakerContract
